export default function Template(params) {
  
   const template = `
    <table style="border-spacing: 0px; border-collapse: collapse;" border="0" cellspacing="0"
    cellpadding="0">
    <tbody>
       <tr>
          <td class="x_outer"
             style="font-family: Arial, sans-serif;padding-top: 5px ; min-width: 600px; border-collapse: collapse; background-color: #fcfaf6; width: 100%;"
             valign="top">
             <table id="x_boxing" class="x_m_boxing"
                style="border-spacing: 0; border-collapse: collapse; margin: 0 auto 0 auto;" border="0" width="640"
                cellspacing="0" cellpadding="0" align="center">
                <tbody>
                   <tr>
                      <td id="x_template-wrapper" class="x_mktoContainer x_boxedbackground"
                         style="word-break: break-word; font-family: Arial,sans-serif; border-collapse: collapse;">
                         <p> </p>
                         <table id="x_hero" class="x_mktoModule x_m_hero"
                            style="border-spacing: 0; border-collapse: collapse;" border="0" width="100%" cellspacing="0"
                            cellpadding="0">
                            <tbody>
                               <tr style="border-bottom: 2px solid #fc7d00">
                                  <td class="x_background" valign="top" bgcolor="ffffff" width="640" height="25px"
                                     data-imagetype="External">
                                     <center>
                                        <img style="margin-top: 30px; height: 60px" src="https://www.grupoaval.com.br/logo.png"/>
                                        <p style="margin-bottom: 20px">
                                            <span style="font-size: 18px; color: #ffffff;">
                                            <strong>
                                                Intranet
                                            </strong>
                                            </span></p>
                                     </center>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                         <table id="x_free-text" class="x_mktoModule x_m_free-text"
                            style="border-spacing: 0; border-collapse: collapse;" border="0" width="100%" cellspacing="0"
                            cellpadding="0" align="center">
                            <tbody>
                               <tr>
                                  <td style="word-break: break-word; font-family: Arial,sans-serif; border-collapse: collapse; background-color: #ffffff;"
                                     valign="top" bgcolor="#FFFFFF">
                                     <center>
                                        <table class="x_table600"
                                           style="border-spacing: 0; border-collapse: collapse; margin: 0 auto 0 auto;"
                                           border="0" width="580" cellspacing="0" cellpadding="0" align="center">
                                           <tbody>
                                       
                                              <tr>
                                                 <td class="x_primary-font x_text"
                                                    style="word-break: break-word; font-family: Arial,sans-serif; font-size: 12px; color: #66705c; line-height: 170%; border-collapse: collapse;">
                                                    <div id="x_text4" class="x_mktoText" style="padding: 25px 0;">
                                                    <p style="font-size: 12px;">Nome</p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #232336;">
                                                    ${params.nome}
                                                    </span></p>
                                                    <p style="font-size: 12px;">Empresa</p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #232336;">
                                                    ${params.empresa}
                                                    </span></p>
                                                    <p style="font-size: 12px;">Telefone</p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #232336;">
                                                    ${params.telefone}
                                                    </span></p>

                                                    <p style="font-size: 12px;">Assunto</p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #232336;">
                                                    ${params.assunto}
                                                    </span></p>

                                                    <p style="font-size: 12px;">Banco</p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #232336;">
                                                    ${params.banco}
                                                    </span></p>
                                                    <p style="text-align: justify;">
                                                    <span style="font-size: 18px; color: #5a5c61;">
                                                     
                                                   
                                                    
                                                    </span></p>
                                                 
                                                      <p style="font-size: 12px;">Messagem</p>
                                                       <p><span style="font-size: 16px;"> ${params.messagem} </span></p>
                                                   



                                                       <p style="font-size: 12px;">Autoriza</p>
                                                       <p><span style="font-size: 16px;"> ${params?.termos ? `<p style="color: green">O cliente autoriza o recebimento de email e sms!</p>` : `<p style="color: red">O cliente não autoriza o recebimento de email e sms!</p>`} </span></p>
                                                    </div>
                                                 </td>
                                              </tr>
                                      
                                           </tbody>
                                        </table>
                                     </center>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                    
                     
           
                         <table id="x_blankSpacea6849bce-b5d6-421a-9901-67787c142474" class="x_mktoModule x_m_blankSpace"
                            style="border-spacing: 0; border-collapse: collapse;padding: 30px 0;" border="0" width="100%" cellspacing="0"
                            cellpadding="0" align="center">
                            <tbody>
                               <tr>
                                  <td style="word-break: break-word; font-family: Arial,sans-serif; border-collapse: collapse; background-color: #fcfaf6;"
                                     valign="top" bgcolor="#fdfeff">
                                     <table class="x_table600"
                                        style="border-spacing: 0px; border-collapse: collapse; margin: 0px auto; height: 61px;"
                                        border="0" width="452" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                           <tr>
                                              <td class="x_primary-font x_title"
                                                 style="font-family: Arial, sans-serif; font-size: 60px; line-height: 90%; color: #32ad84; text-align: left; border-collapse: collapse; width: 450px;">
                                                 <div id="x_copyright" class="x_mktoText">
                                                    <div class="x_p1" style="text-align: center;"><span
                                                          style="color: #828f9d; font-size: 12px;"> © email enviado via formulario Grupo Aval. </span>
                                                           <br>
                                                          </div>
                                                           
                                                 </div>
                                              </td>
                                           </tr>
                                        </tbody>
                                     </table>
                                  </td>
                               </tr>
                            </tbody>
                         </table>
                      </td>
                   </tr>
                </tbody>
             </table>
          </td>
       </tr>
    </tbody>
    </table>
    `
return template;
}