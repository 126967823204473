import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay,Navigation ,Pagination } from "swiper";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";



const options = {
  preset: "triangles",
};
export function Slider() {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    await loadFull(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);



    return(
    
      <div class="slider">

    
    <Swiper
    navigation={true}
    loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
            pagination={{
              dynamicBullets: true,
            }}
            style={{
              "--swiper-navigation-color": "#fc7d00 ",
              "--swiper-pagination-color": "#fc7d00 ",
           
            }}
            lazy={true}
            modules={[Autoplay, Pagination, Navigation]}
            onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
        <SwiperSlide>    <img
          src="./images/slider.png"

          /></SwiperSlide>     <SwiperSlide>    <img
          src="./images/slider2.png"

          /></SwiperSlide>     <SwiperSlide>    <img
          src="./images/slider3.png"

          /></SwiperSlide>     <SwiperSlide>    <img
          src="./images/slider4.png"

          /></SwiperSlide>
 
 <div class="efeitoslide"></div>
 <div class="efeitoslide2"></div>

 <Particles
            className="parallax-bg"
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 300,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "grab",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                      
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
        />
    </Swiper>
    <div class="ui container conteudo-texto">
      <div class="texto">
        <h2 class="ui header">
       O Grupo Aval
          <p class="sub">
       pauta suas ações no aprimoramento constante de seus serviços, evidenciando valores com respeito a seus clientes, melhoria contínua dos processos e o crescimento qualitativo dos colaboradores através de investimento em novas tecnologias e treinamentos constantes.
          </p>
        </h2>
      </div>
      </div>
      </div>
   )
}