import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation ,Pagination } from "swiper";
import './canaiscontato.sass'
const filiais = [ {
id: "ativos",
name: "Ativos S.A",
telefone: "0800 721 7097"
},
{
id: "amc",
name: "Bv Regiões",
telefone: "0800 701 8600"
},
{
id: "amc",
name: "Bv Capitais",
telefone: "3003 1616"
},
{
id: "desco",
name: "Bradesco",
telefone: "0800 770 1516"
},
{
id: "mp",
name: "Mercado pago",
telefone: "0800 770 2343"
},
{
id: "itau",
name: "Itaú",
telefone: "0800 880 8003"
},
{
  id: "prava",
  name: "Pravaler",
  telefone: "0800 880 8087"
},
{
  id: "reco",
  name: "Recovery",
  telefone: "0800 000 1574"
},
{
  id: "day",
  name: "Daycoval",
  telefone: "0800 000 1662"
},
{
  id: "cp",
  name: "Credpago",
  telefone: "0800 779 0306"
}
];
export default memo(function CanaisContatoPagina() {
  return  <Pagina titulo={"Canais de Contato"}> 


      <div class="telefones">
      <h3 class="ui header">
        Canais
        <p class="sub header">
          Entre em contato com a nossa central de atendimento pelos telefones abaixo.
          Caso queira entrar em contato diretamente com uma de nossas filiais, entre na sessão Filiais e localize a mais próxima de você.
        </p>
      </h3>

    </div>


  <Swiper
    navigation={true}
    loop={true}
    slidesPerColumn={2} spaceBetween={30} 
    slidesPerView={4}

            pagination={{
              dynamicBullets: true,
            }}
            style={{
              "--swiper-navigation-color": "#fc7d00 ",
              "--swiper-pagination-color": "#fc7d00 ",
           
            }}
            lazy={true}
            modules={[Autoplay, Pagination, Navigation]}
            onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
   
      {filiais?.map(item => (
        <>
              <SwiperSlide>
     <div class="telefones_contato">
 <div  class="box-tel">
   <p class="nome">{item.name}</p>
   <p class="telefone">{item.telefone}</p>
 </div>
</div>

</SwiperSlide>  
        </>
      ))}
    
    </Swiper>
  
  </Pagina>;
  });
  