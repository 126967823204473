import React, { memo } from 'react';
import { Pagina } from '../../../componentes/pagina';
const termosHtml = `


<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>POL&Iacute;TICA DE PRIVACIDADE</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><strong><u><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>TERMO DE PRIVACIDADE - Grupo Aval</span></u></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>1.INFORMA&Ccedil;&Otilde;ES GERAIS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Pol&iacute;tica de privacidade &eacute; um conjunto de termos que descreve as pr&aacute;ticas adotadas pela <strong>Grupo Aval</strong>, em rela&ccedil;&atilde;o &agrave;s informa&ccedil;&otilde;es dos titulares de dados pessoais &ndash; pessoas f&iacute;sicas.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A presente Pol&iacute;tica de Privacidade se aplica ao tratamento de dados pessoais que a <strong>Grupo Aval</strong> realiza tanto em meios f&iacute;sicos como digitais. Desta forma cont&eacute;m informa&ccedil;&otilde;es a respeito do modo como tratamos &ndash; tanto como operadores quanto controladores de dados pessoais, total ou parcialmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Ao acessar e utilizar nosso site ou aplicativo e/ou contratar os nossos servi&ccedil;os o titular dos dados pessoais, ou seu representante legal, expressamente declara que leu, compreende e aceita o modo que realizamos a coleta, uso e compartilhamento de seus Dados e informa&ccedil;&otilde;es.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Recomendamos a leitura atenta deste documento e caso n&atilde;o concorde com os termos desta Pol&iacute;tica, deve abster-se de utilizar nosso site ou aplicativo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para informa&ccedil;&otilde;es sobre dados pessoais ou exerc&iacute;cio do direito de titular de dados pessoais a <strong>Grupo Aval</strong> disponibiliza um canal oficial &uacute;nico pelo e-mail <strong>ouvidoria@grupoaval.com.br</strong>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>2. OBJETIVOS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Objetivo deste documento &eacute; esclarecer aos interessados acerca dos tipos de dados que s&atilde;o coletados, dos motivos da coleta e da forma como o titular dos dados pessoais poder&aacute; atualizar, gerenciar ou excluir estas informa&ccedil;&otilde;es.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Entende-se por dados pessoais, quaisquer dados que permitam que uma pessoa f&iacute;sica seja identificada direta ou indiretamente, assim, os dados pessoais n&atilde;o se limitam aos dados de qualifica&ccedil;&atilde;o, como nome, CPF e RG, mas tamb&eacute;m incluem padr&otilde;es de comportamento, aquisi&ccedil;&atilde;o de produtos e servi&ccedil;os, d&iacute;vidas, dados de pesquisa, mensagens enviadas, imagem, voz, biometria, dados gen&eacute;ticos, gostos e prefer&ecirc;ncias e absolutamente qualquer informa&ccedil;&atilde;o que possa, de qualquer modo, ser vinculada a uma pessoa f&iacute;sica.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Esta Pol&iacute;tica de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet) e com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais - LGPD).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Esta Pol&iacute;tica de Privacidade poder&aacute; ser atualizada em decorr&ecirc;ncia de eventual atualiza&ccedil;&atilde;o normativa.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>3. DIREITOS DO TITULAR DE DADOS PESSOAIS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> assegura que todos os seus colaboradores, sócios e diretores que tenham acesso aos dados pessoais constantes na presente ficha, comprometem-se a mantê-los como estritamente confidenciais e não utilizar para quaisquer outros fins.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>“Autorizo o acesso e tratamento dos meus dados pessoais, em conformidade com a Lei nº 13.709/18 - Lei Geral de Proteção de Dados Pessoais (LGPD) pela empresa recrutadora, para quaisquer pesquisas que se façam necessárias no curso do processo de recrutamento e seleção. A empresa assegura que todos os seus colaboradores, sócios e diretores que tenham acesso aos dados pessoais constantes na presente ficha, comprometem-se a mantê-los como estritamente confidenciais e não utilizar para quaisquer outros fins.”</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> habilita meios necess&aacute;rios para atender ao direito de informa&ccedil;&atilde;o e para obten&ccedil;&atilde;o de consentimento nos casos necess&aacute;rios garantindo a legalidade do processamento de dados pessoais. No momento da obten&ccedil;&atilde;o ou coleta das informa&ccedil;&otilde;es, a <strong>Grupo Aval</strong> comprometesse a informar as pessoas envolvidas a identidade do Controlador, a finalidade, as poss&iacute;veis comunica&ccedil;&otilde;es ou transfer&ecirc;ncias, e a possibilidade de exercer os direitos estabelecidos na LGPD.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Reconhece e garante a possibilidade de exerc&iacute;cio dos direitos de acesso, retifica&ccedil;&atilde;o, cancelamento, oposi&ccedil;&atilde;o, limita&ccedil;&atilde;o do tratamento e portabilidade, ou seja, a <strong>Grupo Aval</strong> se compromete a cumprir as normas previstas na Lei Geral de Prote&ccedil;&atilde;o de Dados (LGPD), em respeito aos princ&iacute;pios nela previstos, sendo que os dados pessoais dos titulares ser&atilde;o:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;processados de forma l&iacute;cita, leal e transparente;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;coletados apenas para finalidades determinadas, expl&iacute;citas e leg&iacute;timas, n&atilde;o podendo ser tratados posteriormente de uma forma incompat&iacute;vel com essas finalidades;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;coletados de forma adequada, pertinente e limitada &agrave;s necessidades do objetivo para os quais eles s&atilde;o processados;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;exatos e atualizados sempre que necess&aacute;rio, de maneira que os dados inexatos sejam apagados ou retificados quando poss&iacute;vel;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;conservados de uma forma que permita a identifica&ccedil;&atilde;o dos titulares dos dados apenas durante o per&iacute;odo necess&aacute;rio para as finalidades para as quais s&atilde;o tratados;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;tratados de forma segura, protegidos do tratamento n&atilde;o autorizado ou il&iacute;cito e contra a sua perda, destrui&ccedil;&atilde;o ou danifica&ccedil;&atilde;o acidental, adotando as medidas t&eacute;cnicas ou organizativas adequadas;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os titulares de dados pessoais em suas rela&ccedil;&otilde;es com a <strong>Grupo Aval</strong>, seja por uso do site ou aplicativo, bem como todas as demais situa&ccedil;&otilde;es que possam advir desta rela&ccedil;&atilde;o, em algum tipo de tratamento de seus dados pessoais, possui os seguintes direitos, conferidos pela Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito de confirma&ccedil;&atilde;o e acesso: o titular de dados pessoais pode obter a confirma&ccedil;&atilde;o de que seus dados pessoais s&atilde;o ou n&atilde;o objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito de retifica&ccedil;&atilde;o: &eacute; o direito do titular de dados pessoais de obter da <strong>Grupo Aval</strong>, sem demora injustificada, a retifica&ccedil;&atilde;o dos dados pessoais inexatos que lhe digam respeito;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito &agrave; elimina&ccedil;&atilde;o dos dados (direito ao esquecimento): &eacute; o direito do titular de dados pessoais de ter seus dados apagados, do banco de dados, do site ou aplicativo quando desnecess&aacute;rios ou n&atilde;o atenderem uma obriga&ccedil;&atilde;o legal ou contratual de guarda;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito &agrave; limita&ccedil;&atilde;o do tratamento dos dados: &eacute; o direito do titular de dados pessoais de limitar o tratamento de seus dados pessoais, podendo obt&ecirc;-la quando contesta a exatid&atilde;o dos dados, quando o tratamento for il&iacute;cito, quando o site ou aplicativo n&atilde;o precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecess&aacute;rios;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito de portabilidade dos dados: &eacute; o direito do titular de dados pessoais de receber os dados pessoais que lhe digam respeito (por exemplo: que tenha fornecido ao site ou aplicativo), num formato estruturado, de uso corrente e de leitura autom&aacute;tica, e o direito de transmitir esses dados a outro site ou aplicativo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito de n&atilde;o ser submetido a decis&otilde;es automatizadas: &eacute; o direito do titular de dados pessoais de n&atilde;o ficar sujeito a nenhuma decis&atilde;o tomada exclusivamente com base no tratamento automatizado, incluindo a defini&ccedil;&atilde;o de perfis, que produza efeitos na sua esfera jur&iacute;dica ou que o afete significativamente de forma similar.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O titular de dados pessoais poder&aacute; exercer os seus direitos por meio de comunica&ccedil;&atilde;o formalizada e escrita enviada ao Encarregado de Dados (DPO), atrav&eacute;s do contato: <strong>ouvidoria@grupoaval.com.br</strong>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para tanto, o titular dever&aacute; especificar explicitamente no corpo do e-mail:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Nome completo ou raz&atilde;o social, n&uacute;mero do CPF (Cadastro de Pessoas F&iacute;sicas, da Receita federal do Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jur&iacute;dica, da Receita Federal do Brasil) do titular e, se for o caso, do seu representante legal;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Direito que deseja exercer junto ao site ou aplicativo BP Solu&ccedil;&otilde;es;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Data do pedido e assinatura do titular de dados pessoais;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Todo documento que possa demonstrar ou justificar o exerc&iacute;cio de seu direito.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O titular de dados pessoais ser&aacute; informado, sobre o seu exerc&iacute;cio de direito, no prazo previsto legalmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>4. DEVER DE N&Atilde;O FORNECER DADOS DE TERCEIROS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Durante a utiliza&ccedil;&atilde;o do site ou aplicativo, ou durante qualquer rela&ccedil;&atilde;o com o a <strong>Grupo Aval</strong>, a fim de resguardar e de proteger os direitos de terceiros, o titular de dados pessoais dever&aacute; fornecer somente seus dados pessoais, e n&atilde;o dados de terceiros, salvo de seus representados legais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5. COLETA DE DADOS PESSOAIS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Na <strong>Grupo Aval</strong> temos um alto compromisso com a seguran&ccedil;a das informa&ccedil;&otilde;es e com o cumprimento dos requisitos legais. Nesse sentido, para garantir a confidencialidade, disponibilidade e integridade das informa&ccedil;&otilde;es, em particular os dados pessoais, sistemas, redes, aplica&ccedil;&otilde;es e bases de dados utilizados para o seu tratamento, f&iacute;sicas ou virtuais s&atilde;o protegidas com a melhor medida t&eacute;cnica e organizacional poss&iacute;vel.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Desenvolvemos regularmente avalia&ccedil;&otilde;es dos riscos associados &agrave; seguran&ccedil;a da informa&ccedil;&atilde;o e &agrave; prote&ccedil;&atilde;o de dados pessoais, analisando a situa&ccedil;&atilde;o frente aos riscos e definindo planos de a&ccedil;&atilde;o em conformidade com a legisla&ccedil;&atilde;o vigente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Definimos nesta Pol&iacute;tica de Prote&ccedil;&atilde;o de Dados de cumprimento obrigat&oacute;rio para as partes envolvidas no tratamento dos dados pessoais, estabelecendo diretrizes para uniformizar o tratamento de dados pessoais nos processos da <strong>Grupo Aval</strong>, visando a privacidade dos dados pessoais, sua integridade, disponibilidade, confidencialidade e visando disseminar a cultura de seguran&ccedil;a dos dados pessoais em conson&acirc;ncia com os princ&iacute;pios da LGPD de finalidade, necessidade, transpar&ecirc;ncia, adequa&ccedil;&atilde;o, livre acesso, qualidade dos dados, seguran&ccedil;a e preven&ccedil;&atilde;o.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Desenvolvemos procedimentos de controle de acesso, seguran&ccedil;a de sistemas e comunica&ccedil;&otilde;es, gest&atilde;o de incidentes, viola&ccedil;&otilde;es de seguran&ccedil;a e procedimentos de suporte &agrave; informa&ccedil;&atilde;o, bem como desenvolvemos a&ccedil;&otilde;es de sensibiliza&ccedil;&atilde;o e forma&ccedil;&atilde;o necess&aacute;rias para garantir o cumprimento dessa pol&iacute;tica e procedimentos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Destarte, as informa&ccedil;&otilde;es pessoais referem-se a qualquer informa&ccedil;&atilde;o relacionada a uma pessoa f&iacute;sica natural identificada ou identific&aacute;vel. Uma pessoa natural identific&aacute;vel &eacute; aquela que pode ser identificada, direta ou indiretamente, em particular com refer&ecirc;ncia a um elemento de identifica&ccedil;&atilde;o, seja um nome, n&uacute;mero de identifica&ccedil;&atilde;o, dados de localiza&ccedil;&atilde;o, e-mail pessoal, contato de WhatsApp, identifica&ccedil;&atilde;o online ou um ou mais fatores espec&iacute;ficos da identidade f&iacute;sica, psicol&oacute;gica, gen&eacute;tica, mental, econ&ocirc;mica, cultural ou social dessa pessoa.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Como operadores de dados recebemos e tratamos dados pessoais em nome dos controladores, respeitando as previs&otilde;es contratuais e legais, que podem variar desde realizar a negocia&ccedil;&atilde;o de ativos devidos em contrapresta&ccedil;&atilde;o pelos Servi&ccedil;os contratados com nosso cliente controlador e, quando necess&aacute;rio. Assim sendo, os dados pessoais que a <strong>Grupo Aval</strong> trata, visa atender aos prop&oacute;sitos operacionais e comerciais, se restringindo ao necess&aacute;rio para o desenvolvimento adequado dos servi&ccedil;os oferecidos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Como controladores coletamos, armazenamos e processamos as informa&ccedil;&otilde;es e, em particular, dados pessoais necess&aacute;rios para cumprimento legal/contratual e para a exata presta&ccedil;&atilde;o de nossos servi&ccedil;os.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Outrossim, em atendimento as legisla&ccedil;&otilde;es e regulamentos nacionais a <strong>Grupo Aval</strong>, em casos espec&iacute;ficos, necessita compartilhar informa&ccedil;&otilde;es e dados pessoais que tratam com autoridades judiciais, policiais ou governamentais ou outros terceiros com quem a T<strong>oledo Piza Advogados Associados</strong> seja obrigada por lei, norma regulat&oacute;ria ou ordem judicial a compartilhar os Dados, ou terceiros comprometidos contratualmente em manter a confidencialidade, sigilo e a seguran&ccedil;a dos dados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados pessoais, coletados pelo site ou aplicativo t&ecirc;m por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o titular de dados pessoais e a fazer cumprir as solicita&ccedil;&otilde;es realizadas por meio do preenchimento de formul&aacute;rios.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados pessoas poder&atilde;o ser utilizados tamb&eacute;m com uma finalidade comercial, para personalizar o conte&uacute;do oferecido ao titular de dados pessoais, bem como para dar subs&iacute;dio ao site ou aplicativo para a melhora da qualidade e funcionamento de seus servi&ccedil;os.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados de cadastro ser&atilde;o utilizados para permitir o acesso do titular de dados pessoais a determinados conte&uacute;do do site ou aplicativo, exclusivos para titular de dados pessoais cadastrados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A coleta de dados relacionados ou necess&aacute;rios &agrave; execu&ccedil;&atilde;o de um contrato ou de presta&ccedil;&atilde;o de servi&ccedil;os eventualmente firmado com o titular de dados pessoais ter&aacute; a finalidade de conferir &agrave;s partes seguran&ccedil;a jur&iacute;dica, al&eacute;m de facilitar e viabilizar a conclus&atilde;o do neg&oacute;cio.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O tratamento de dados pessoais para finalidades n&atilde;o previstas nesta Pol&iacute;tica de Privacidade somente ocorrer&aacute; mediante comunica&ccedil;&atilde;o pr&eacute;via ao titular de dados pessoais ou seu representante legal, sendo que, em qualquer caso, os direitos e obriga&ccedil;&otilde;es aqui previstos permanecer&atilde;o aplic&aacute;veis.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1 Tipos de dados coletados:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1.1 Dados de identifica&ccedil;&atilde;o do titular de dados pessoais para realiza&ccedil;&atilde;o de cadastro:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A utiliza&ccedil;&atilde;o, pelo titular de dados pessoais, de determinadas funcionalidades do site ou aplicativo depender&aacute; de cadastro, sendo que, nestes casos, os seguintes dados do titular de dados pessoais ser&atilde;o coletados e armazenados:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; CPF;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; CNPJ;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; Nome;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; E-mail;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; Telefone (s);</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&ndash; Dados de curr&iacute;culo;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1.2 Dados informados no formul&aacute;rio de contato:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados eventualmente informados pelo titular de dados pessoais que utilizar os formul&aacute;rios de contato disponibilizado no site ou aplicativo, incluindo o teor da mensagem enviada, ser&atilde;o coletados e armazenados de forma segura e pelo tempo previsto legal ou contratualmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1.3 Dados relacionados &agrave; execu&ccedil;&atilde;o de contratos firmados com o usu&aacute;rio:&nbsp;</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para as tratativas de negocia&ccedil;&atilde;o ou presta&ccedil;&atilde;o de servi&ccedil;os, eventualmente firmado com os titulares de dados pessoais, no site ou aplicativo, poder&atilde;o ser coletados e armazenados outros dados relacionados ou necess&aacute;rios a sua execu&ccedil;&atilde;o, incluindo o teor de eventuais comunica&ccedil;&otilde;es tidas com o titular de dados pessoais atrav&eacute;s do chat.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1.4 Registros de acesso:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Em atendimento &agrave;s disposi&ccedil;&otilde;es do art. 15, caput e par&aacute;grafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do titular de dados pessoais ser&atilde;o coletados e armazenados, enquanto o t&eacute;rmino do tratamento de dados pessoais ocorrer&aacute; nas seguintes hip&oacute;teses:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>I &ndash; Verifica&ccedil;&atilde;o de que a finalidade foi alcan&ccedil;ada ou de que os dados deixaram de ser necess&aacute;rios ou pertinentes ao alcance da finalidade espec&iacute;fica almejada;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>II &ndash; Fim do per&iacute;odo de tratamento;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>III &ndash; Comunica&ccedil;&atilde;o do titular, inclusive no exerc&iacute;cio de seu direito de revoga&ccedil;&atilde;o do consentimento conforme disposto no &sect; 5&ordm; do art. 8&ordm; desta Lei, resguardado o interesse p&uacute;blico; ou</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>IV &ndash; Determina&ccedil;&atilde;o da autoridade nacional, quando houver viola&ccedil;&atilde;o ao disposto nesta Lei.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.1.5 Dados sens&iacute;veis:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Com base no artigo 11 da LGPD, o tratamento de dados pessoais sens&iacute;veis somente poder&aacute; ocorrer nas seguintes hip&oacute;teses:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>I - Quando o titular ou seu respons&aacute;vel legal consentir, de forma espec&iacute;fica e destacada, para finalidades espec&iacute;ficas;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>II - Sem fornecimento de consentimento do titular, nas hip&oacute;teses em que for indispens&aacute;vel para:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>a) cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>b) tratamento compartilhado de dados necess&aacute;rios &agrave; execu&ccedil;&atilde;o, pela administra&ccedil;&atilde;o p&uacute;blica, de pol&iacute;ticas p&uacute;blicas previstas em leis ou regulamentos;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>c) exerc&iacute;cio regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral, este &uacute;ltimo nos termos da Lei n&ordm; 9.307, de 23 de setembro de 1996 (Lei de Arbitragem) ;g) garantia da preven&ccedil;&atilde;o &agrave; fraude e &agrave; seguran&ccedil;a do titular, nos processos de identifica&ccedil;&atilde;o e autentica&ccedil;&atilde;o de cadastro em sistemas eletr&ocirc;nicos, resguardados os direitos mencionados no art. 9&ordm; desta Lei e exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a prote&ccedil;&atilde;o dos dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&sect; 1&ordm; Aplica-se o disposto neste artigo a qualquer tratamento de dados pessoais que revele dados pessoais sens&iacute;veis e que possa causar dano ao titular, ressalvado o disposto em legisla&ccedil;&atilde;o espec&iacute;fica.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&sect; 2&ordm; Nos casos de aplica&ccedil;&atilde;o do disposto nas al&iacute;neas &ldquo;a&rdquo; e &ldquo;b&rdquo; do inciso II do caput deste artigo pelos &oacute;rg&atilde;os e pelas entidades p&uacute;blicas, ser&aacute; dada publicidade &agrave; referida dispensa de consentimento, nos termos do inciso I do caput do art. 23 desta Lei.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>I - Portabilidade de dados quando consentido pelo titular;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>II - A portabilidade de dados quando solicitada pelo titular;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>III - As transa&ccedil;&otilde;es financeiras e administrativas resultantes do uso e da presta&ccedil;&atilde;o dos servi&ccedil;os de que trata este par&aacute;grafo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Destarte a <strong>Grupo Aval</strong> pode, em algum momento, tratar dados pessoais sens&iacute;veis, desde que atenda ao previsto legal supracitado.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.2 Fundamento jur&iacute;dico para o tratamento dos dados pessoais:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong>, respeita a sua privacidade e est&aacute; fortemente comprometida com a prote&ccedil;&atilde;o dos Dados Pessoais e com o cumprimento dos requisitos da Lei Geral de Prote&ccedil;&atilde;o de Dados (Lei n&ordm; 13.709/18).</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Essa Pol&iacute;tica de Privacidade e Prote&ccedil;&atilde;o de Dados Pessoais informa como a <strong>Grupo Aval</strong>, sociedade inscrita no CNPJ/MF sob o n&ordm; 02.735.428/0001-08, com sede na Pra&ccedil;a Ramos de Azevedo, n&ordm; 206, 13&ordm; Andar, conjuntos 1310, 1320, 1330 e 1340 &ndash; Centro &ndash; CEP: 01037-010, registrada na OAB/SP sob n&ordm; 4.262, coleta, trata e protege seus Dados Pessoais no &acirc;mbito de suas atividades e, ainda, fornece informa&ccedil;&otilde;es sobre os direitos dos Titulares.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Ao utilizar os servi&ccedil;os do site ou aplicativo, o titular de dados pessoais est&aacute; consentindo com a presente Pol&iacute;tica de Privacidade.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O titular de dados pessoais tem o direito de retirar seu consentimento a qualquer momento, n&atilde;o comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poder&aacute; ser feita pelo e-mail: <strong>ouvidoria@grupoaval.com.br</strong>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O consentimento dos relativamente ou absolutamente incapazes, especialmente de menores de 18 (dezoito) anos, apenas poder&aacute; ser firmado se devidamente assistidos por um dos pais ou respons&aacute;vel legal.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O tratamento de dados pessoais sem o consentimento do titular de dados pessoais apenas ser&aacute; realizado em raz&atilde;o de interesse leg&iacute;timo ou para as hip&oacute;teses previstas em lei, ou seja, dentro outras, as seguintes:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Execu&ccedil;&atilde;o de contrato ou de procedimento preliminares relacionados a contrato do qual seja parte o titular de dados pessoais, a pedido do titular dos dados;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Exerc&iacute;cio regular de direito em processo judicial, administrativo ou arbitral, esse &uacute;ltimo nos termos da Lei n&deg; 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Prote&ccedil;&atilde;o da vida ou da incolumidade f&iacute;sica do titular dos dados ou de terceiros;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><u><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'><span style="text-decoration:none;">&nbsp;</span></span></u></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><u><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span></u></em></strong><strong><em><u><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Atender aos interesses leg&iacute;timos do controlador ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a prote&ccedil;&atilde;o dos dados pessoais;</span></u></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Prote&ccedil;&atilde;o do cr&eacute;dito, inclusive quanto ao disposto na legisla&ccedil;&atilde;o <strong><em>pertinente</em></strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.3 Prazo de conserva&ccedil;&atilde;o dos dados pessoais:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Como regra geral, a <strong>Grupo Aval</strong> s&oacute; retem as informa&ccedil;&otilde;es e os dados pessoais pelo tempo necess&aacute;rio e definido em lei ou normativas para dar cumprimento as finalidades definidas no tempo em que os dados foram obtidos. Em geral, uma vez conclu&iacute;da a presta&ccedil;&atilde;o do servi&ccedil;o, os dados s&atilde;o bloqueados para n&atilde;o se submeterem a qualquer tratamento al&eacute;m de mant&ecirc;-los dispon&iacute;veis para os titulares, controladores, Administra&ccedil;&otilde;es P&uacute;blicas, Ju&iacute;zes e Tribunais visando atender poss&iacute;veis responsabilidades decorrentes do tratamento realizado.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para determinar os per&iacute;odos de reten&ccedil;&atilde;o de dados, a <strong>Grupo Aval</strong> contempla as Leis locais, as obriga&ccedil;&otilde;es contratuais e as expectativas e requisitos dos titulares. Quando findo prazo de guarda e conserva&ccedil;&atilde;o exclu&iacute;mos ou destru&iacute;mos com a devida seguran&ccedil;a.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>5.4 Destinat&aacute;rios e transfer&ecirc;ncia dos dados pessoais:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Como regra geral a <strong>Grupo Aval</strong> apenas comunica dados pessoais a terceiros, ou lhes fornece acesso, nos casos necess&aacute;rios para desenvolver uma presta&ccedil;&atilde;o adequada do servi&ccedil;o solicitado, para cumprir a obriga&ccedil;&otilde;es contratuais, jur&iacute;dicas, fiscais, legais e corporativas, ou para o desenvolvimento de determinados processos ou atividades como operadores de dados e devidamente adequadas ao previsto pela LGPD e pela Autoridade Nacional de Prote&ccedil;&atilde;o de Dados &ndash; ANPD.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados pessoais do titular de dados pessoais poder&atilde;o ser compartilhados com nossos clientes controladores de dados e fornecedores que viabilizam o neg&oacute;cio, sempre mediante contratos de confidencialidade.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> se compromete a garantir a prote&ccedil;&atilde;o dos seus dados de acordo com as regras mais rigorosas, por meio de cl&aacute;usulas contratuais espec&iacute;ficas para determinada transfer&ecirc;ncia, cl&aacute;usulas padr&atilde;o contratuais, normas corporativas globais ou certificados digitais e c&oacute;digos de conduta regularmente emitidos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>6. DO TRATAMENTO DOS DADOS PESSOAIS:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>6.1 Do respons&aacute;vel pelo tratamento dos dados (data controller):</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O controlador, respons&aacute;vel pelo tratamento dos dados pessoais do titular de dados pessoais, &eacute; a pessoa f&iacute;sica ou jur&iacute;dica, a autoridade p&uacute;blica, a ag&ecirc;ncia ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> atua como operador de dados, em nome de cliente Controlador de dados pessoais - que possui v&iacute;nculo direto com os titulares de dados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Como controlador de dados pessoais a <strong>Grupo Aval</strong> se limita aos tratamentos de dados de seus funcion&aacute;rios e por dados coletados diretamente dos titulares dos dados pessoais, seja por meio do site, aplicativo ou outros meios necess&aacute;rios para cumprimento de obriga&ccedil;&atilde;o legal, contratual ou, atrav&eacute;s de termo de consentimento dos titulares de dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>6.2 Do encarregado de prote&ccedil;&atilde;o de dados (data protection officer):</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O encarregado de prote&ccedil;&atilde;o de dados (data protection officer - DPO) &eacute; o profissional indicado pelo controlador de dados pessoais, e suas atividades, nos termos da lei, consistem em aceitar reclama&ccedil;&otilde;es e comunica&ccedil;&otilde;es dos titulares, prestar esclarecimentos e adotar provid&ecirc;ncias; receber comunica&ccedil;&otilde;es da autoridade nacional e adotar provid&ecirc;ncias; orientar os funcion&aacute;rios e os contratados da entidade a respeito das pr&aacute;ticas a serem tomadas em rela&ccedil;&atilde;o &agrave; prote&ccedil;&atilde;o de dados pessoais; e executar as demais atribui&ccedil;&otilde;es determinadas pelo controlador ou estabelecidas em normas complementares.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para quaisquer d&uacute;vidas referente aos dados pessoais, em que a <strong>Grupo Aval</strong> atue como controlador de dados, o Encarregado poder&aacute; ser contatado pelo e-mail: <strong>ouvidoria@grupoaval.com.br</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Seguran&ccedil;a no site da <strong>Grupo Aval</strong> no tratamento dos dados pessoais do titular de dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>As m&iacute;dias disponibilizadas pela <strong>Grupo Aval</strong> destinam-se a facilitar ao p&uacute;blico em geral o conhecimento das atividades que realiza, produtos e servi&ccedil;os que presta, bem como para possibilitar a contrata&ccedil;&atilde;o de determinados servi&ccedil;os.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O uso do site implica a aceita&ccedil;&atilde;o expressa e plena das condi&ccedil;&otilde;es aqui estabelecidas, sem preju&iacute;zo daqueles detalhes que podem se aplicar a alguns dos servi&ccedil;os espec&iacute;ficos oferecidos por meio do site.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Com objetivo de manter as informa&ccedil;&otilde;es, publicadas nos portais, atualizadas, os conte&uacute;dos podem ser modificados, corrigidos, exclu&iacute;dos ou aditados a qualquer momento, raz&atilde;o pela qual ser&aacute; conveniente verificar sua validade e precis&atilde;o em fontes oficiais. Da mesma forma, essa pol&iacute;tica de privacidade poder&aacute; se sujeitar a altera&ccedil;&otilde;es, corre&ccedil;&otilde;es ou modifica&ccedil;&otilde;es, a fim de mant&ecirc;-la adequada &agrave;s necessidades da organiza&ccedil;&atilde;o, bem como as legisla&ccedil;&otilde;es vigentes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>a. Responsabilidade pela opera&ccedil;&atilde;o do site:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> n&atilde;o pode garantir a aus&ecirc;ncia de erros no acesso aos servi&ccedil;os web ou ao seu conte&uacute;do, embora a desenvolva os mecanismos necess&aacute;rios para reduzir esses cen&aacute;rios e corrigi-los e atualiz&aacute;-los o mais r&aacute;pido poss&iacute;vel.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Da mesma forma, a <strong>Grupo Aval</strong> n&atilde;o pode ser responsabilizada por quaisquer erros, interfer&ecirc;ncias ou interrup&ccedil;&otilde;es do servi&ccedil;o que possam surgir de causas como v&iacute;rus de computador, avarias de telefone ou rede, desconex&otilde;es e outras falhas motivado por causas al&eacute;m do propriet&aacute;rio do site. A <strong>Grupo Aval</strong> reserva-se o direito de suspender temporariamente o funcionamento dos servi&ccedil;os web, a qualquer momento, sempre que necess&aacute;rio para manuten&ccedil;&atilde;o, repara&ccedil;&atilde;o, atualiza&ccedil;&atilde;o ou melhoria.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A empresa tamb&eacute;m se reserva o direito de negar ou retirar o acesso ao portal e/ou aos servi&ccedil;os oferecidos sem aviso pr&eacute;vio, a seu pedido ou de terceiros, aos usu&aacute;rios que n&atilde;o cumpram essas Condi&ccedil;&otilde;es Gerais de Uso. Ainda, a <strong>Grupo Aval</strong> n&atilde;o &eacute; respons&aacute;vel pelos conte&uacute;dos que podem ser publicados pelos usu&aacute;rios ou por terceiros atrav&eacute;s dos f&oacute;runs, coment&aacute;rios ou redes sociais associadas ao site, embora sejam fornecidos os meios precisos, para remover este tipo de conte&uacute;do e para garantir o cumprimento da legisla&ccedil;&atilde;o aplic&aacute;vel, bem como o respeito pelos direitos das pessoas afetadas e ordem p&uacute;blica.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Caso os usu&aacute;rios detectem qualquer conte&uacute;do que os afetem a esse respeito, recomenda-se entrar em contato com a <strong>Grupo Aval</strong> o mais r&aacute;pido poss&iacute;vel, atrav&eacute;s dos meios fornecidos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>b. Responsabilidade pelos links:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os links contidos em nossos servi&ccedil;os web podem levar a servi&ccedil;os web externos, gerenciados por terceiros, a fim de fornecer ao usu&aacute;rio acesso a informa&ccedil;&otilde;es ou recursos relacionados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> n&atilde;o gere, analisa, nem pode ser responsabilizado pelo conte&uacute;do, opera&ccedil;&atilde;o ou informa&ccedil;&atilde;o dos s&iacute;tios Web com a qual n&atilde;o se mant&eacute;m qualquer rela&ccedil;&atilde;o.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A <strong>Grupo Aval</strong> n&atilde;o pode ser responsabilizada por quaisquer danos e preconceitos que possam surgir a partir dessas p&aacute;ginas vinculadas. Da mesma forma, a inclus&atilde;o dessas conex&otilde;es externas n&atilde;o implicar&aacute; qualquer tipo de associa&ccedil;&atilde;o, fus&atilde;o ou participa&ccedil;&atilde;o com as entidades conectadas.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O site se compromete a aplicar as medidas t&eacute;cnicas e organizativas aptas a proteger os dados pessoais de acessos n&atilde;o autorizados e de situa&ccedil;&otilde;es de destrui&ccedil;&atilde;o, perda, altera&ccedil;&atilde;o, comunica&ccedil;&atilde;o ou difus&atilde;o de tais dados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para a garantia da seguran&ccedil;a, ser&atilde;o adotadas solu&ccedil;&otilde;es que levem em considera&ccedil;&atilde;o: as t&eacute;cnicas adequadas; os custos de aplica&ccedil;&atilde;o; a natureza, o &acirc;mbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do titular de dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;;'>O site utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confi&aacute;vel, de maneira que a transmiss&atilde;o dos dados entre o servidor e o titular de dados pessoais ocorra de maneira cifrada e encriptada.</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>No entanto, o site e o aplicativo se eximem de responsabilidade por culpa exclusiva de terceiros, como um caso de ataque de hackers ou crackers, ou culpa exclusiva do titular de dados pessoais, como no caso em que ele mesmo transfere seus dados a terceiro. O site se compromete, ainda, a comunicar o titular de dados pessoais em prazo adequado caso ocorra algum tipo de viola&ccedil;&atilde;o da seguran&ccedil;a de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A viola&ccedil;&atilde;o de dados pessoais &eacute; uma viola&ccedil;&atilde;o de seguran&ccedil;a que provoque, de modo acidental ou il&iacute;cito, a destrui&ccedil;&atilde;o, a perda, a altera&ccedil;&atilde;o, a divulga&ccedil;&atilde;o ou o acesso n&atilde;o autorizado a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Por fim, o site da <strong>Grupo Aval</strong> se compromete a tratar os dados pessoais de titulares de dados pessoais com confidencialidade e dentro dos limites legais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>7. DADOS DE NAVEGA&Ccedil;&Atilde;O (COOKIES):</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O site da <strong>Grupo Aval</strong> e seus dom&iacute;nios podem usar cookies (pequenos arquivos de informa&ccedil;&otilde;es que o servidor envia ao computador da pessoa que acessa a p&aacute;gina) para o bom funcionamento e visualiza&ccedil;&atilde;o dos sites pelo usu&aacute;rio, bem como a coleta de estat&iacute;sticas. <strong><span style=";">Em particular, os cookies s&atilde;o desde os PHP, em especial PHPSESSID, que &eacute; o identificador &uacute;nico de sess&atilde;o e n&atilde;o coleta dados, at&eacute; os fornecidos por terceiros como o Google Analytics (ferramenta de terceiro e com padr&otilde;es pr&oacute;prios).</span></strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Tamb&eacute;m podemos vir a utilizar cookies adicionais para analisar o tr&aacute;fego em nosso site e os padr&otilde;es de acesso dos nossos usu&aacute;rios, que nos ajudam a melhorar a experi&ecirc;ncia e o aproveitamento do site.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Al&eacute;m disso, outros mecanismos como ferramentas de busca (exemplo, Google, Bing e similares) e redes sociais (exemplo, Facebook, Instagram ou similares) podem fornecer ferramentas no estilo de plug-ins, que se conectam ao site para gerar informa&ccedil;&otilde;es atrav&eacute;s de cookies e exibir an&uacute;ncios relacionados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O fato do usu&aacute;rio ser impactado por an&uacute;ncios referentes aos mesmos produtos ou servi&ccedil;os, n&atilde;o significa que foi identificado por visitar um website. Na maioria das vezes, essas informa&ccedil;&otilde;es s&atilde;o coletadas de forma an&ocirc;nima, com base no perfil de navega&ccedil;&atilde;o, sem que sua identidade seja revelada. Destacamos que o usu&aacute;rio, pode desabilitar esses cookies diretamente nas configura&ccedil;&otilde;es de seu navegador, mas isso pode afetar a sua experi&ecirc;ncia em nosso site.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>a. Aceita&ccedil;&atilde;o da pol&iacute;tica de Cookies:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A ORGANIZA&Ccedil;&Atilde;O exibe informa&ccedil;&otilde;es sobre sua Pol&iacute;tica de Cookies na parte inferior de todas as p&aacute;ginas do portal a cada login para tomada de ci&ecirc;ncia.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Vale lembrar que aqui fica vis&iacute;vel quem o titular autoriza visualizar os seus cookies e para quais finalidades, deixando a op&ccedil;&atilde;o de escolha para o titular.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Uma vez entrando na p&aacute;gina web e autorizando, fica salvo e n&atilde;o precisa consentir novamente, salvo se houverem altera&ccedil;&otilde;es no site, deste modo precisa solicitar novo consentimento.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Aceite dos Cookies: Este aviso n&atilde;o ser&aacute; exibido novamente ao acessar qualquer p&aacute;gina do portal durante esta sess&atilde;o.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Modificar as Configura&ccedil;&otilde;es: Poder&aacute; obter mais informa&ccedil;&otilde;es sobre quais s&atilde;o os cookies, conhecer a pol&iacute;tica de cookies do GRUPO BPe modificar as configura&ccedil;&otilde;es do navegador para restringir ou bloquear os cookies da empresa a qualquer momento. No caso de restringir ou bloquear os cookies, pode haver uma funcionalidade reduzida da web.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Seguir navegando ou se movendo pela barra de rolagem: caso em que consideramos aceite de seu uso.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>b. Como alterar as configura&ccedil;&otilde;es do cookie:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Pode-se restringir, bloquear ou excluir os cookies de qualquer site, utilizando seu navegador. Em cada navegador a opera&ccedil;&atilde;o &eacute; diferente, a fun&ccedil;&atilde;o &apos;Ajuda&apos; ir&aacute; mostrar como fazer.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;InternetExplorer: windows.microsoft.com/es-xl/internet-explorer/delete-manage cookies#ie=&quot;ie-10&quot;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;FireFox: support.mozilla.org/es/kb/Borrar%20cookies</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Chrome: support.google.com/chrome/answer/95647?hl=&quot;es&quot;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Segoe UI Symbol",sans-serif;'>✓</span><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;Safari: www.apple.com/es/privacy/use-of-cookies/</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>8. DIVULGA&Ccedil;&Atilde;O E PUBLICIDADE:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>a. E-mails:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O endere&ccedil;o de e-mail e o nome dos titulares de dados pessoais em nosso banco de dados &eacute; importante para que possamos nos comunicar frente aos assuntos decorrentes das atividades desenvolvidas. &Eacute; por este motivo que armazenamos essas informa&ccedil;&otilde;es em nosso banco de dados interno.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>b. SMS e WhatsApp:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Tamb&eacute;m utilizamos os canais SMS e WhatsApp, como uma forma de comunica&ccedil;&atilde;o para o qual nos d&aacute; seu consentimento expresso, livre e informado de forma individual.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>As finalidades dos consentimentos v&atilde;o desde:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&bull; Receber mensagens por WhatsApp, como informa&ccedil;&otilde;es referentes a Negocia&ccedil;&atilde;o de D&eacute;bitos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&bull; Consentir que a Organiza&ccedil;&atilde;o trate seus dados pessoais, por WhatsApp, para os fins de melhor presta&ccedil;&atilde;o dos servi&ccedil;os;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&bull; Envio de comunica&ccedil;&otilde;es, por WhatsApp, referentes a Negocia&ccedil;&atilde;o de D&eacute;bitos.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A qualquer momento esses consentimentos podem ser cancelados.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O cancelamento acontece tamb&eacute;m por e-mail enviando um comunicado sobre o desejo de cancelamento para&nbsp;</span><a href="mailto:ouvidoria@grupoaval.com.br"><strong><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>ouvidoria@grupoaval.com.br</span></strong></a><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>c. Confidencialidade:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os dados coletados por quaisquer meios de cadastro ficam armazenados no banco interno da <strong>Grupo Aval</strong>, dispon&iacute;veis para os nossos colaboradores/parceiros (comprometidos com o sigilo e confidencialidade) para melhor atender aos titulares dos dados pessoais.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Cada comunica&ccedil;&atilde;o que recebemos &eacute; confidencial e tratamos isso com muita seriedade e compromisso.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>d. Mensagens n&atilde;o solicitadas:</span></em></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Caso acredite que esteja recebendo mensagens que n&atilde;o solicitou, solicitamos ao titular dos dados pessoais que encaminhe um relato para o e-mail do Encarregado da BP GEST&Atilde;O<strong>, ouvidoria@grupoaval.com.br</strong>, que retornar&aacute; dentro dos prazos previstos legalmente.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>9. DAS ALTERA&Ccedil;&Otilde;ES:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>A presente vers&atilde;o desta Pol&iacute;tica de Privacidade foi atualizada pela &uacute;ltima vez em: 07/11/2022.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O editor se reserva o direito de modificar, a qualquer momento, as presentes normas, especialmente para adapt&aacute;-las &agrave;s evolu&ccedil;&otilde;es do site<strong>&nbsp;https://www.grupoaval.com.br</strong> ou aplicativo, seja pela disponibiliza&ccedil;&atilde;o de novas funcionalidades, seja pela supress&atilde;o ou modifica&ccedil;&atilde;o daquelas j&aacute; existentes.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>O titular de dados pessoais ser&aacute; explicitamente notificado em caso de altera&ccedil;&atilde;o desta pol&iacute;tica atrav&eacute;s de comunicado de novos termos a partir do pr&oacute;prio site ou aplicativo.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>10. DO DIREITO APLIC&Aacute;VEL E DO FORO:</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para a solu&ccedil;&atilde;o das controv&eacute;rsias decorrentes do presente instrumento, ser&aacute; aplicado integralmente o Direito brasileiro.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Os eventuais lit&iacute;gios dever&atilde;o ser apresentados no foro da comarca em que se encontra a sede do editor do site.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><em><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>ENCARREGADO PELO TRATAMENTO DE DADOS - DPO</span></em></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Moises B de Souza - Encarregado de Proteção de Dados</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Para mais informações sobre dados pessoais ou exercício do direito de titular de dados pessoais o Grupo Aval</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>disponibiliza um canal oficial único pelo e-mail </span><a href="mailto:ouvidoria@grupoaval.com.br"><strong><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>ouvidoria@grupoaval.com.br</span></strong></a></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>&nbsp;</p>
`
export default memo(function pdpPagina() {
    return <Pagina titulo={"Política de Privacidade"}>

<>
<section>
<div dangerouslySetInnerHTML={ { __html : termosHtml  }}/> 

    
</section>
</>

    </Pagina>
})