import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { SidebarContext } from "../../componentes/sidebarContext";
import { Slider } from "../../componentes/slider";
import menu from '../../rotas/menu'

const base = window.location.host;
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };}
export function Header() {
  const {isOpen,toggle} = useContext(SidebarContext)
  const [fixed, SetFixed] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [window.location]);
  useEffect(() => {
    const handleScroll = event => {
      SetFixed(window.scrollY > 200)
    };
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [window.location]);
  return (<>
    <header>
      <div className={fixed ? 'ui sticky fixed nav-menu' : 'ui nav-menu'}>
        <div className="ui container">
          <div className="ui menu secondary">
            <Link to="/" className="item logo"><img src="./logo.png" /></Link>
            <div className="menu right">

     {windowDimensions.width < 800 ?
               <>
                   <div className="item sidebar" onClick={() => toggle(!isOpen)} >
            <i style={{color: '#000'}} className="sidebar icon"></i>
          </div>
              </> : <>

              {menu?.map((menu , i ) => (
                <>
                {menu.nav && 
                  <Link key={i} className={`ui item ${menu?.drop?.length > 0 ? 'dropdown simple' : ''}`}  to={menu.path} >
                    <span className="text">{menu?.titulo}</span>
                    {menu?.drop &&
                      <div class="menu">
                        {menu?.drop.map(drop => (
                          <Link className="item" to={drop.path} >{drop.titulo}</Link>
                        ))}
                      </div>
                    }

                  </Link>
}
                </>
              ))}
              </>


              }
              <a className="item estilo" target="_blank" href="https://www.paguejaonline.com.br">PagueJá</a>
            </div>
          </div>
        </div>
      </div>
      <Slider />
    </header>



  </>)
}