
import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
import './treinamento.sass'
const pilares = [
  {icon: './images/SVG/comece.svg',
  titulo: 'Comprometimento e apoio da alta direção',
  },
  {icon: './images/SVG/trabalho-em-equipe.svg',
  titulo: 'Instância responsável',
  },
  {icon: './images/SVG/apresentacao.svg',
  titulo: 'Análise de perfil e riscos',
  },
  {icon: './images/SVG/lista.svg',
  titulo: 'Regras e instrumentos',
  },
  {icon: './images/SVG/rede.svg',
  titulo: 'Monitoramento contínuo',
  }
]
export default memo(function TreinamentoPagina() {
  return  <Pagina titulo={"Treinamento"}> 
  
  <>
  
  <div class="ui container">
    <div class="ui container conteudo">


    <p>
      Faz parte do nosso programa de integridade treinamentos periódicos para todos os funcionários, a partir de um plano de capacitação com o objetivo de treinar as pessoas sobre o conteúdo e práticas das políticas de conduta e ética. É importante manter registros dos treinamentos realizados, com a informação de todos que foram treinados e em quais temas.
    </p>
<div class="ui divider"></div>
<div class="pilares">
<h2 class="ui header">

  5 Pilares para o programa de integridade
</h2>


<div class="ui five column stackable grid container">
  {pilares.map(item => (

<div class="column boxes">
<h3 class="ui icon header" >
 <img src={item.icon}/>
 <div class="content">
   {item.titulo}

 </div>
</h3>
 </div>

  ))}


</div>



</div>






    </div>


</div>
</>
  
  </Pagina>;
  });
  