import { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import './quemsomos.sass'
export function QuemSomos() {
  const [modalvideo, setOpenModalVideo] = useState(false);
  const Videoaval = useRef(null);

  const attemptPlay = () => {
    Videoaval &&
    Videoaval.current &&
    Videoaval.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };
  const stopPlay = () => {
    Videoaval &&
    Videoaval.current &&
    Videoaval.current.pause().catch(error => {
        console.error("Error attempting to stop", error);
      });
  };
  useEffect(()=> {
    if(modalvideo) {
      attemptPlay();
    } else {
      stopPlay();
    }
  },[modalvideo])
    return(<>
      <div className="ui container quemsomos">
    <div className="ui stackable two column grid">
      <div className="column" style={{display: 'flex'}}>
        <div className="texto">
          <h2 className="ui header">
            Há mais de 42 anos 
            <p className="header sub">
              Atuando com as melhores práticas do mercado. Possuímos tecnologia própria buscando sempre inovação e novos meios de recuperação.
            </p>
          </h2>
        </div>
      </div>
      <div className="column">
        <div className="video">

       <img width="100%" height="100%" src="./images/video.png"/>
       <i className="icon play circle"  onClick={() => setOpenModalVideo(true)}></i>

        </div>
      </div>
    </div>
  </div>
  <Modal
      closeIcon
      onClose={() => setOpenModalVideo(false)}
      onOpen={() => setOpenModalVideo(true)}
      open={modalvideo}
    >
      <Modal.Header>Grupo Aval</Modal.Header>
      <Modal.Content>
      <video
          ref={Videoaval}
          id="videoaval"
          width="100%"
            height="100%"
            playsInline
            loop
            controls>
  <source src="https://www.grupoaval.com.br/video/grupoaval.mp4" type="video/mp4"/>
</video>
      </Modal.Content>

    </Modal>
    </>)
}