import logo from './logo.svg';
import 'semantic-ui-css/semantic.min.css'
import { Header } from './template/topo/header';
import './sass/aval.scss';
import PaginaPrincipal from './paginas/principal/index'
import { Link, RouterProvider, useLocation } from 'react-router-dom';
import routes from './rotas/routes';
import { useEffect, useLayoutEffect, useState } from 'react';
import { SidebarContext } from './componentes/sidebarContext';
function App() {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false)
    }, 1000)

  })
  const [sidebarIsOpen,setSidebarIsOpen] = useState(false);
  function toggle() {
    setSidebarIsOpen(!sidebarIsOpen)
  }

  return (
    <div className="App" >
 
        {load ? <>
          <div class="load" >
            <div>
              <img src="logo.png" class="logo" />
              <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
            </div>
          </div>
        </>
          : <>

          <SidebarContext.Provider value={{isOpen: sidebarIsOpen, toggle}}>

          <RouterProvider router={routes} />

          </SidebarContext.Provider>
          </>
        }

      
    </div>


  );
}

export default App;
