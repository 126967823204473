import './pandape.sass'
export function Pandape() {
    return (<> 
    <div style={{margin: '0 1rem'}}>
        <div className="flex pandape bar">
            <div>
                <h2>
                    VENHA FAZER<br></br>PARTE DO NOSSO <br></br> <strong>TIME</strong>
                </h2>
            </div>
            <div className="flex">
                <a className='ui button' href="https://grupoaval.pandape.com.br/" target="_blankcode ">
                    Veja nossas vagas
                </a>
            </div>


            <div className='efeito' style={{backgroundImage: "url('./images/customerexperience-IMG-2.png')"}}></div>
            </div>
            </div>
    </>)
}