import { Link } from "react-router-dom";
import { Canais } from "../../componentes/canais";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

export function Rodape() {
    return(<>
      
    <footer class="rodape">
    <div className="ui grid container">
      <div className="sixteen wide tablet five wide computer column">
        <h3 className="ui header">Nossos canais</h3>
        <div>
          Horário: Segunda a sexta das 08h às 20h
          <br />Sábado das 08h às 14h
        </div>
        <div className="space">


          {/* <router-link
            class="space ui button tiny basic orange"
            :to="{name: 'canais'}"
          >Veja nosso telefones de contato</router-link> */}
        <Link to="/contato/canais " className="space ui button tiny basic orange">Veja nosso telefones de contato</Link>

        </div>
      </div>
      <div className="sixteen wide tablet five wide computer column">
        <h3 className="ui header">Unidades Grupo Aval</h3>
        <div className="space">
          <span>O grupo aval possui unidades nos estado de São Paulo procure a unidade mais próxima de você!</span>
        </div>
        {/* <router-link class="space ui button tiny basic orange" :to="{name: 'filiais'}">Buscar</router-link> */}

        <Link to="/ogrupo/filiais " className="space ui button tiny basic orange">Fale conosco</Link>

        <div className="item redes">
          <a  href="https://www.facebook.com/grupoavalcobranca/" target="_blank" class="ui circular facebook icon basic button">
            <i className="facebook icon"></i>
          </a>
          <a target="_blank" href="https://instagram.com/grupoaval_oficial?igshid=1c9oigqrmvk4t" class="ui circular instagram icon basic button">
            <i className="instagram icon"></i>
          </a>
          <a href="https://www.linkedin.com/company/gpaval" target="_blank" class="ui circular linkedin icon basic button">
            <i className="linkedin icon"></i>
          </a>
        </div>
        <div className="item">
                <h3 className="ui header">Associações</h3>
                        <div className="space">
</div>
                <img src="https://www.grupoaval.com.br/images/geoc.png" height="80px"/>
        
        </div> 
      </div>

      <div className="sixteen wide tablet four wide computer column">
        <div className="ui vertical menu fluid">
        <div className="item" >
            <div className="header">Sobre o grupo aval</div>
            <div className="menu">
  
              <Link to="/ogrupo" className="item">Sobre o grupo aval</Link>
              <Link to="/ogrupo/filiais" className="item">Nossas unidades</Link>
              <Link to="/ogrupo/parceiros " className="item">Parceiros</Link>
      
            </div>
          </div>

          <div className="item" >
            <div className="header">Politicas</div>
            <div className="menu">
            <Link to="/politica/politica-de-privacidade" className="item">Política de privacidade</Link>
            <Link to="/politica/seguranca-da-informacao" className="item">Política de Segurança da informação</Link>
            <Link to="/politica/termos-de-uso" className="item">Termos de uso</Link>
      
            </div>
          </div>

          <div className="item">
            <div className="header">Fale conosco</div>
            <div className="menu">
            <Link to="/contato " className="item">Fale conosco</Link>
            <a target="_blank" href="https://grupoaval.pandape.infojobs.com.br/" className="item">Trabalhe conosco</a>
              {/* <router-link class="item" :to="{name : 'contato'}">Fale conosco</router-link>
              <Link class="item" to="vagas">Trabalhe conosco</Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="row centered">
        <div className="logo" src="\images\grupoaval.png"></div>
        <div className="copyright">© Grupo Aval | Todos os direitos reservados</div>
      </div>
    </div>
  </footer>

    {/* <Canais></Canais> */}
    </>)
}