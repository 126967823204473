import React, { memo, useState } from 'react';
import { Pagina } from '../../../componentes/pagina';
import {
    Link as Target,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
  } from "react-scroll";
import { Popup } from 'semantic-ui-react';

export default memo(function PrivacidadeSIPagina() {

    let lastKnownScrollPosition = 0;
    let ticking = false;
    const [scrollY, SetScrollY] = useState();


    document.addEventListener("scroll", function (e) {
        lastKnownScrollPosition = window.scrollY;
        if (!ticking) {
          window.requestAnimationFrame(function () {
            SetScrollY(lastKnownScrollPosition);
            ticking = false;
          });
          ticking = true;
        }
      });

    return <Pagina titulo={"Política de Segurança da informação"}>

<>

      <div className="seguranca">
        <div className="grid" onScroll={() => console.log(`teste ${this}`)}>
          <div className="menu">
            <div className={`menu-box ${scrollY > 300 ? "fixed" : ""}`}>
              <ul id="pillar-page-sections">
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="objetivo"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                      <Popup content='Objetivo' trigger={<span>•</span>} />
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="abrangencia"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
      <Popup content='Abrangência' trigger={<span>•</span>} />

                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="responsavel"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                          <Popup content='Responsável' trigger={<span>•</span>} />
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="principios-da-política-de-segurança-da-informacao"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                                              <Popup content='Princípios da política de segurança da informação' trigger={<span>•</span>} />

              
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="divulgacao-e-declaracao-de-responsabilidade"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
      <Popup content='Divulgação e declaração de responsabilidade' trigger={<span>•</span>} />

              
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="diretrizes-da-de-seguranca-da-informacao"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
  <Popup content='Diretrizes da segurança da informação' trigger={<span>•</span>} />

                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="gestao-seguranca-da-informacao"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
          <Popup content='  Gestão da segurança da informação' trigger={<span>•</span>} />

                
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="monitoramento-e-auditoria"
                    spy={true}
                    smooth={true}
                    duration={500}
                  > 
                  <Popup content='  Monitoramento e auditoria' trigger={<span>•</span>} />
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="penalidades"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                           <Popup content='Penalidades' trigger={<span>•</span>} />

          
                  </Target>
                </li>
                <li>
                  <Target
                    activeClass="active"
                    className="link"
                    to="revisao-e-manutencao"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
        <Popup content='Revisão e manutenção' trigger={<span>•</span>} />

                 
                  </Target>
                </li>
              </ul>
            </div>
          </div>
          <div className="conteudo">
            <div className="background">
            </div>
            <div className=" texto">

            <Element name="objetivo" className="element">
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="objetivo">Objetivo</h3>
                  <p>
                    Definir as diretrizes de segurança da informação para que
                    todos os usuários e sistemas do <strong> Grupo Aval</strong>  sigam tais práticas.
                  </p>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element name="abrangencia" className="element">
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="abrangencia">Abrangência</h3>
                  <p>
                    Esta Política de Segurança da Informação tem abrangência
                    sobre todas as unidades do
                      <strong>Grupo aval</strong>. Todas as
                    regras aqui estabelecidas devem ser aplicadas aos clientes
                    internos, estagiários, aprendizes, líderes, executivos,
                    diretores, sócios e conselho administrativo, doravante
                    denominados usuários, no que se refere à proteção da
                    informação e uso de recursos tecnológicos das
                      <strong>Grupo aval</strong> e para os
                    prestadores de serviços, parceiros e fornecedores, caso
                    estes realizem qualquer forma de acesso ou manipulação das
                    informações ou utilizem recursos tecnológicos do
                      <strong>Grupo aval</strong>
                  </p>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element name="responsavel" className="element">
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="responsavel">Responsável</h3>
                  <p>
                    A Administração do
                      <strong>Grupo aval</strong> é
                    responsável pela viabilização das condições necessárias para
                    a devida aplicabilidade desta Política de Segurança da
                    Informação, o Comitê de Gestão de Segurança da Informação,
                    formado por representantes das principais áreas da empresa,
                    os quais compõem o Comitê de Integridade, é responsável pela
                    validação e aprovação das diretrizes e a área de Compliance
                    é responsável pela atualização dessa Política.
                  </p>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element
                name="principios-da-política-de-segurança-da-informacao"
                className="element"
              >
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="principios-da-política-de-segurança-da-informacao">
                    Princípios da política de segurança da informação
                  </h3>
                  <p>
                    Entende-se por informação todo e qualquer conteúdo ou dado
                    que tenha valor para organização ou seus clientes. Ela pode
                    estar guardada para uso restrito ou exposta ao cliente para
                    consulta ou manuseio. Pode estar impressa, escrita, pode ser
                    falada, transmitidas por e-mails ou outros meios
                    eletrônicos. Independente da forma apresentada ou o meio do
                    qual a informação é compartilhada ou armazenada, a
                    informação é o maior ativo do <strong> Grupo Aval</strong> e de seus clientes e, por isso, essencial ao
                    negócio.
                  </p>
                  <p>
                    Uma vez que se é trabalhado com informações de terceiros
                    (clientes), além de prover segurança para a sua própria
                    operação, é exigido também o cumprimento contratual de
                    segurança destas informações, sendo o<strong> Grupo Aval</strong> responsável por qualquer incidente ou divulgação
                    intencional ou arbitrária delas. Por esses motivos, a
                    informação deverá ser devidamente protegida e utilizada de
                    modo ético e seguro, garantindo confiabilidade através da
                    proteção da:
                  </p>
                  <ol style={{ listStyle: "lower-latin" }}>
                    <li>
                      Confidencialidade: Garantir que o acesso à informação seja
                      obtido somente por pessoas autorizadas;
                    </li>
                    <li>
                      Integridade: Garantir a exatidão e completude da
                      informação e dos métodos de seu processamento, bem como da
                      transparência no tratamento com os públicos envolvidos; e
                    </li>
                    <li>
                      Disponibilidade: Garantir que as pessoas autorizadas
                      tenham acesso à informação, sempre que necessário.
                    </li>
                  </ol>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element
                name="divulgacao-e-declaracao-de-responsabilidade"
                className="element"
              >
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="divulgacao-e-declaracao-de-responsabilidade">
                    Divulgação e declaração de responsabilidade
                  </h3>
                  <p>
                    A Política de Segurança da Informação deve ser de
                    conhecimento de todos e deve ser divulgada da seguinte
                    forma:
                    
                  </p>
<ol>
<li>
  Impressa;
  </li>
  <li>
  Via campanhas de Segurança da Informação;
  </li>
  <li>
  Por meio digital, através da intranet corporativa.
  </li>
</ol>
                  <p>
                    A política deve estar disponível em local de acesso dos
                    clientes internos e protegida contra alterações.
                  </p>

                  <p>
                    Todos os clientes internos, temporários, aprendizes,
                    estagiários, líderes, executivos, diretores, sócios, além de
                    prestadores de serviços, parceiros e fornecedores que
                    realizem qualquer forma de acesso ou manipulação das
                    informações ou utilizem recursos tecnológicos da Toledo Piza
                    Advogados Associados devem aderir formalmente ao
                    <span style={{ color: "red" , fontWeight: 'bold'}}>
                    “Termo de Responsabilidade, Compromisso de Sigilo Sobre Segurança da Informação e Confidencialidade.”
                    </span>
                    comprometendo-se a agir de acordo com a Política e Normas de
                    segurança da informação, além do Código de Ética e Conduta
                    do     <strong>Grupo Aval</strong>.
                  </p>
    
                </div>
              </Element>
              <Element
                name="diretrizes-da-de-seguranca-da-informacao"
                className="element"
              >
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="diretrizes-da-de-seguranca-da-informacao">
                    
                    Diretrizes da segurança da informação
                  </h3>
                  <p>
                    Para endereçar todo o esforço e manutenção necessária para a
                    Segurança da Informação, o     <strong>Grupo Aval</strong>
                    estabelece as seguintes diretrizes.
                  </p>
                  <p>
                    <strong>
                      Uma estrutura de Gestão da Segurança da Informação será
                      estabelecida e mantida com apoio da Alta Administração,
                      através de um Sistema de Gestão de Segurança da Informação
                      (SGSI);
                    </strong>
                  </p>
                  <p>
                    A informação deverá ser utilizada com senso de
                    responsabilidade e de modo ético e seguro por todos, em
                    benefício exclusivo dos negócios corporativos;
                  </p>

                  <p>
                    o     <strong>Grupo Aval</strong> reserva-se o direito de
                    monitorar e registrar todo o uso das informações geradas,
                    armazenadas ou veiculadas na empresa;
                  </p>

                  <p>
                    Todos os ativos de informação devem ser devidamente
                    identificados, classificados e monitorados;
                  </p>

                  <p>
                    A identificação de cada cliente interno da Toledo Piza
                    Advogados Associados é única, pessoal e intransferível,
                    qualificando-o como responsável pelas ações realizadas;
                  </p>

                  <p>
                    Os riscos identificados deverão ser analisados,
                    classificados e apresentados ao Comitê de Gestão da
                    Segurança da Informação (CGSI), que deliberará sobre o
                    tratamento adequado para tais;
                  </p>
                  <p>
                    o     <strong>Grupo Aval</strong>, segue, documenta e
                    mantêm atualizadas as leis que regulamentam suas atividades,
                    bem como dos aspectos de propriedade intelectual;
                  </p>
                  <p>
                    Quando razões tecnológicas ou determinações superiores
                    tornarem impossível a aplicação dos requisitos previstos
                    nesta política o solicitante deverá documentá-las com todas
                    as informações do fato por um documento escrito e
                    comunicá-las imediatamente à área de Segurança da Informação
                    através do endereço de e-mail:
                    ouvidoria@toledopizaadvogados.com.br para que possibilite a
                    adoção de medidas alternativas que minimizem os riscos, bem
                    como um plano de ação para corrigi-los, monitorá-los ou
                    eliminá-los.
                  </p>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element
                name="gestao-seguranca-da-informacao"
                className="element"
              >
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="gestao-seguranca-da-informacao">
                    Gestão da segurança da informação
                  </h3>
                  <p>
                    Para manter um nível satisfatório de segurança constitui-se
                    o Comitê de Gestão de Segurança da Informação (CGSI) que
                    adotará as diretrizes apresentadas:
                  </p>

                  <p>
                    O controle de acesso dos clientes internos ou terceiros aos
                    ativos de informação deve ser devidamente controlado e
                    aprovado pelo responsável pela informação (gerência ou,
                    diretoria), a qual o acesso permitirá a manipulação, quer
                    seja para simples consulta ou para alteração;
                  </p>

                  <p>
                    O uso do e-mail sob domínios de propriedade da Toledo Piza
                    Advogados Associados será permitido para clientes internos e
                    para terceiros somente quando for necessário, e por tempo
                    determinado pela gerência da área solicitante mediante a
                    assinatura do Termo de responsabilidade. Este tempo poderá
                    ser prorrogado mediante nova solicitação da gerência da
                    área;
                  </p>

                  <p>
                    Cópias de segurança (backup) devem ser realizadas através de
                    mídias específicas para as informações que são consideradas
                    vitais para os sistemas e para a retomada das atividades das
                    áreas, em caso de indisponibilidade;
                  </p>

                  <p>
                    Regras para o desenvolvimento seguro de sistemas e softwares
                    devem ser estabelecidas e aplicadas para os desenvolvimentos
                    realizados dentro da organização;
                  </p>

                  <p>
                    Terceiros somente deverão ter acessos a sistemas legados da
                    empresa quando acompanhados por recurso interno;
                  </p>

                  <p>
                    A concessão de acesso remoto para os clientes internos deve
                    ser autorizada formalmente e solicitada ao departamento de
                    Tecnologia da Informação pela gerência da área solicitante,
                    ocasião em que deverá ser indicado o tipo de acesso,
                    permissão e as informações a serem acessadas, sendo de
                    responsabilidade do solicitante dos atos oriundo do acesso;
                  </p>

                  <p>
                    Dispositivo móvel entende-se qualquer equipamento eletrônico
                    com atribuições de mobilidade no manuseio da informação e
                    destina-se ao uso para realização das atividades de trabalho
                    e para comunicação com a empresa, fornecedores ou clientes,
                    devendo ser utilizado somente para esta finalidade, podendo
                    haver especificações e/ou restrições de acordo com
                    normativas contratuais de nossos clientes contratantes;
                  </p>

                  <p>
                    As informações devem ser classificadas e manuseadas de
                    acordo com a confidencialidade e as proteções necessárias,
                    da seguinte forma: Pública, Sensível, Privada e Confidencial
                    e devem ser tratadas, armazenadas e descartadas de maneira
                    correta para garantir os aspectos de segurança da informação
                    no negócio do     <strong>Grupo Aval</strong> e nas
                    informações dos seus clientes;
                  </p>
                  <p>
                    As responsabilidades de todos quanto à segurança da
                    informação devem ser definidas, seguindo requisitos mínimos
                    de boa conduta e ética;
                  </p>

                  <p>
                    Os ativos tangíveis e intangíveis de informação devem ser
                    identificados de forma individual, inventariados, protegidos
                    e monitorados de acessos indevidos. As mídias devem ser
                    gerenciadas de forma adequada, conforme os requisitos de
                    segurança da informação;
                  </p>
                  <p>
                    Um conjunto de regras para garantir a padronização das
                    técnicas criptográficas deve ser estabelecido, incluindo a
                    aplicação adequada das mesmas e as responsabilidades para
                    manter a segurança no transporte ou armazenamento das
                    informações independente do meio utilizado. Quanto à
                    transmissão de informações, este recurso é utilizado para
                    garantir a privacidade na comunicação dos dados da Toledo
                    Piza Advogados Associados e de seus clientes;
                  </p>
                  <p>
                    Um processo de gestão de mudanças deve estar em vigor para
                    garantir que controles e modificações nos sistemas ou
                    recursos de processamento da informação sejam realizados com
                    planejamento, a fim de não ocasionar falhas operacionais ou
                    de segurança no ambiente produtivo da organização;
                  </p>
                  <p>
                    Medidas de segurança devem ser adotadas para garantir a
                    proteção das informações de maneira eficaz e reduzir os
                    riscos de acesso não autorizado, perda ou dano à informação;
                  </p>
                  <p>
                    Os riscos devem ser identificados por meio de um processo
                    estabelecido para análise de vulnerabilidades, ameaças e
                    impactos sobre os processos nos aspectos de segurança da
                    informação (Confidencialidade, Integridade e
                    Disponibilidade);
                  </p>
                  <p>
                    Todos os incidentes que afetem a segurança da informação
                    devem ser reportados à área de Segurança da Informação
                    através da abertura de chamado no sistema de Service Desk
                    utilizado na empresa. Estes analisarão o incidente e tomarão
                    as ações devidas, repassando a tratativa às áreas
                    responsáveis;
                  </p>
                  <p>
                    Todos os incidentes de segurança devem ser reportados para a
                    área de Segurança da Informação, para que sejam analisados,
                    avaliados e tratados pela área responsável;
                  </p>
                  <p>
                    As responsabilidades do departamento de Tecnologia da
                    Informação e suas subdivisões devem ser estabelecidas, bem
                    como as restrições do uso de ativos tecnológicos da
                    organização;
                  </p>
                  <p>
                    Devem ser definidas regras para garantir que não ocorram
                    violações jurídicas, regulamentares ou contratuais nos
                    requisitos de segurança da informação na organização;
                  </p>

                  <p>
                    Devem ser estipuladas diretrizes para garantir que o acesso
                    físico às instalações onde os ativos de TI e as informações
                    críticas a continuidade do negócio estejam armazenados seja
                    controlado de forma a garantir a sua disponibilidade,
                    integridade e confidencialidade.
                  </p>
                </div>
              </Element>
              <Element name="monitoramento-e-auditoria" className="element">
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="monitoramento-e-auditoria">
                    Monitoramento e auditoria
                  </h3>
                  <p>
                    O   <strong>Grupo aval</strong> monitora e registra todo o
                    uso das informações geradas, armazenadas ou veiculadas na
                    empresa. Para tanto a organização mantem controles
                    apropriados e trilhas de auditoria ou registros de
                    atividades em todos os pontos e sistemas que a empresa
                    julgou necessário para reduzir os riscos, e reservar-se o
                    direito de:
                  </p>
                  <p>
                    Implantar outros sistemas de monitoramento de acesso às
                    estações de trabalho, servidores internos e externos,
                    correio eletrônicos, navegação, Internet, dispositivos
                    móveis ou wireless e outros componentes da rede. A
                    informação gerada por estes sistemas de monitoramento poderá
                    ser usada para identificar usuários e respectivos acessos
                    efetuados;
                  </p>
                  <p>
                    Inspecionar qualquer arquivo que esteja na rede, no disco
                    local da estação ou qualquer outro ambiente, visando
                    assegurar o rígido cumprimento desta PSI;
                  </p>
                  <p>
                    Instalar outros sistemas de proteção e detecção de invasão
                    para prevenir a segurança das informações e dos perímetros
                    de acesso, levando em consideração normas específicas e
                    contratual de nossos clientes contratantes, e;
                  </p>
                  <p>
                    Instalar câmeras nas instalações físicas, levando em
                    consideração normas específicas e contratual de nossos
                    clientes contratantes.
                  </p>

                  {/* <div class="alert alert-primary" role="alert">
                A administração da TOLEDO PIZA ADVOGADOS adotou esta política de segurança para proteger as informações utilizadas pela empresa no atingimento de suas metas comerciais. Todos os colaboradores devem observar as políticas descritas neste documento.
</div> */}
                </div>
              </Element>
              <Element name="penalidades" className="element">
                
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="penalidades">Penalidades</h3>
                  <p>
                    Para toda e qualquer infração à Política de Segurança da
                    Informação (PSI), às Normas de Segurança da Informação e ao
                    Código de Ética e Conduta, deverá ser aberto um incidente de
                    segurança da informação, tratado de acordo com o Plano de
                    Tratamento de Incidentes de Segurança da Informação e
                    informado ao Comitê de Gestão de Segurança da Informação
                    (CGSI) e, por conseguinte, apurada através de procedimentos
                    internos conduzidos pelas áreas de Compliance e Segurança do
                        <strong>Grupo Aval</strong>.
                  </p>
                  <p>
                    Caso o Comitê de Gestão de Segurança da Informação (CGSI)
                    julgue cabível, o cliente interno envolvido poderá, enquanto
                    durar o processo de apuração interna, ser afastado da função
                    ou suspenso.
                  </p>
                  <p>
                    Ao cliente interno suspeito de cometer violações à Política
                    e Normas de Segurança da Informação, deverá ser assegurado
                    tratamento justo e correto, sendo que toda e qualquer medida
                    resultante de sua infração deverá ser aplicada com
                    proporcionalidade à ocorrência com base no Código de Ética e
                    Conduta, Termo de Confidencialidade e Aceite da Política de
                    Segurança da Informação (PSI) e legislações vigentes.
                  </p>
                  <p></p>

                  <p>
                    o     <strong>Grupo Aval</strong> exonera-se de toda e
                    qualquer responsabilidade decorrente do uso indevido,
                    negligente ou imprudente dos recursos e serviços concedidos
                    aos seus clientes internos, reservando-se o direito de punir
                    os infratores, analisar dados e evidências para obtenção de
                    provas a serem utilizadas nos processos investigatórios e
                    adotar as medidas legais cabíveis.
                  </p>
                </div>
              </Element>
              <Element name="revisao-e-manutencao" className="element">
                <div>
                <br></br>
      <br></br>
      <br></br>
      <br></br>
                  <h3 id="revisao-e-manutencao">Revisão e manutenção</h3>
                  <p>
                    Esta PSI deverá ser revisada anualmente ou quando uma
                    mudança significativa ocorrer na organização.
                  </p>
                </div>
              </Element>
              <br></br>
              <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>


            </div>
          </div>
        </div>
      </div>
</>

    </Pagina>
})