import { useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "semantic-ui-react";
import './segundavia.sass'

export function SegundaVia() {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [modalPagueja , setModalPagueja] = useState(false);
    const onSubmit = data => {
        setModalPagueja(true)
        setTimeout(() => {
            window.location.replace(`https://www.paguejaonline.com.br/acesso.aspx?cpf=${data.cpf}&celular=${data.celular}&origem=www.grupoaval.com.br'`);
            setModalPagueja(false);
        }, 1000);
    }

    return (<>
    <div className="segundavia">
        <div className="tabs" >
            <div className="ui container">
                <div className="ui secondary menu">
                    <a className="active item">2° via de boleto</a>
                </div>
                <form  className="ui container flex" onSubmit={handleSubmit(onSubmit)}>
                    <div className="segundavia">
                        <h4 className="ui center aligned icon header">
                            Agora ficou mais fácil gerar sua 2° via
                            <div className="sub header">Informe o número do seu CNPJ/CPF e Telefone para realizar seu pagamento.</div>
                        </h4>
                        <div className="ui input">
                            {/* <the-mask
              :mask="['###.###.###-##', '##.###.###/####-##']"
              placeholder="235.145.111-30"
              v-model="cpf"
            /> */}
                              <input    placeholder="235.145.111-30"  {...register("cpf", { required: true })} />

                        </div>
                        <div className="ui input">
                            {/* <the-mask
              :mask="['(##) ####-####', '(##) #####-####'] "
              placeholder="Celular"
              v-model="celular"
            /> */}
                  <input placeholder="Celular" {...register("celular", { required: true })} />

                        </div>
                        <button  type="submit" className="ui orange button" >
                            <i className="barcode icon"></i>
                            <span>Gerar boleto</span>
                        </button>
                    </div>
                </form>
            </div>

            </div>
            </div>

            <Modal
      closeIcon
      onClose={() => setModalPagueja(false)}
      onOpen={() => setModalPagueja(true)}
      open={modalPagueja}
      
    >
      <Modal.Header>Grupo Aval</Modal.Header>
      <Modal.Content>
      <div className="description" >
                            <img src="https://www.paguejaonline.com.br/images/azul.svg" height="50" />
                            <p className="mt-1"> Você está sendo direcionado para o PagueJá </p>
                        </div>
      </Modal.Content>

    </Modal>
    </>)
}