

import React, { memo, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Pagina } from '../../componentes/pagina';
import './unidades.sass'
import GoogleMapReact from 'google-map-react';
const centers = [
{
  position: {
    //SÂO PAULO
    lat: -23.54532875,
    lng: -46.6373159
  },
  grupo: 'sp',
},
{
  position: {
    //Araçatuba
    lat: -21.207525,
    lng: -50.446053
  },
  grupo: 'ara',
},
]

const locais = [{
  position: {
    //SÂO PAULO
    lat: -23.54532875,
    lng: -46.6373159
  },
  grupo: 'sp',
  titulo: "Sâo Paulo",
  descricao: `Rua Formosa, 367 – 9º andar, CJ 960 e 28º andar, CJ 2820
  Centro – São Paulo – SP <br>
  CEP 01049-000<br></br>
  <strong>Telefone</strong></br>
  <br> (11) 3334-3000 (11) 3305-3900<br>
  <br> <strong>Fax</strong>: (11) 3305-3926<br><br>
  <strong>E-mail</strong>: diretoria@grupoaval.com.br<br>
  `,

  active: true
},
{
  titulo: "Araçatuba",
  active: false,
  grupo: 'ara',
  subfilial: [
    {
      grupo: 'ara',
      position: {
        //Araçatuba
        lat: -21.195383,
        lng: -50.434557
      },
      titulo: "Aviação",
      descricao: `Rua da Aviação, nº 365,  Bairro Santana <br>
   – Araçatuba – SP<br>
      CEP  16050-555
      `
    },
    {
      grupo: 'ara',
      position: {
        //Araçatuba
        lat: -21.209245,
        lng: -50.4489353
      },
      titulo: "Marechal",
      descricao: ` Rua Marechal Deodoro, 883 e 887 <br>
      Bairro São João – Araçatuba – SP<br>
      CEP  16025-020<br></br>
      <br> <strong>Telefone</strong></br>
      <br>(18) 2103 9460 <br>
      `
    },
    // {
    //   position: {

    //     //Araçatuba
    //     lat: -21.209245,
    //     lng: -50.4489353
    //   },
    //   titulo: "Savério",
    //   descricao: ` Araçatuba na Rua Savério Safiotti, 120 / 132 <br>Bairro Paraíso – Araçatuba - SP <br> CEP 16050-130,
    //   `,
    //   grupo: 'ara',
    // }

  ]
},

];


const AnyReactComponent = ({ text }) => (
  <div style={{
    color: 'white',
    background: 'linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%) ',
    padding: '5px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '.3rem',
    transform: 'translate(-50%, -50%)'
  }}>
    {text}
  </div>
);

export default memo(function UnidadesPagina() {
  const defaultProps = {
    zoom: 15
  };


  const [unidade,setUnidade] = useState(locais[0])
  const [center,setCenter] = useState(locais[0].position)

  useEffect(() => {
setCenter(unidade.position)
  },[unidade])
  return <Pagina titulo={"Unidades"}>
    <>

      <div class="background">
        <div class="ui container conteudo">
          <div id="filiais" class="filiais">

            <div class=" endereco">
              <h2 class="ui header">
                <div class="content">Onde estamos ?</div>
              </h2>
              <div class="filiais">
                {
                  locais.map(local => (

                    <>
                      <a onClick={() => setUnidade(local)} className={local.grupo === unidade.grupo ? 'active' : ''}>
                        <div >{local.titulo}</div>
                      </a>
                    </>
                  ))
                }

{

locais.filter(f => f.grupo === unidade.grupo).map(item => (
<>
<div class="subfilial">
{item?.subfilial?.length > 0 && item?.subfilial.map(filial => (<>

                <span onClick={() => { setUnidade(filial)}}>{filial.titulo}</span>
</>))
}

</div>





</>

))
}
       
          

         </div>
              </div>
              <div class="ui segment basic"><h4  class="ui header">
             {unidade.titulo}
           
         
      
         </h4>
         <div dangerouslySetInnerHTML={ { __html : unidade.descricao  }}/> 


         </div>

            <div class="map" style={{position: 'relative'}}>

              {

                
    centers.filter(f => {return f.grupo === unidade.grupo}).map(map => (
      <>
        <GoogleMapReact
                style={{ height: "200px" }}
                bootstrapURLKeys={{ key: "AIzaSyCR0zZ8O-NXQr9BvODkoeByPVnX3441mRI" }}
                defaultCenter={map.position}
                defaultZoom={defaultProps.zoom}

              >
                <AnyReactComponent
                  lat={-23.54532875}
                  lng={-46.6373159}
                  text={'Sâo Paulo'}
                />
                <AnyReactComponent
                  lat={-21.207525}
                  lng={-50.446053}
                  text={'Aguapei'}
                />
                <AnyReactComponent
                  lat={-21.209245}
                  lng={-50.4489353}
                  text={'Marechal'}
                />
              </GoogleMapReact>
      </>
    ))
              }
       
            </div>
          </div>
        </div>
      </div>

    </>


  </Pagina>;
});
