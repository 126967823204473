import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
export default memo(function MvvPagina() {
  return  <Pagina titulo={"Missão visão valores"}> 
  
  <>
  <div class="ui container">
    <div class="ui container conteudo">

         <div class="ui container ">
      <p>

    O Grupo Aval , especializada em comunicação nos âmbitos extrajudicial e judicial, os principais direcionadores das políticas adotadas pela empresa, que norteiam as ações e relações com sócios, clientes, parceiros,fornecedores, colaboradores e comunidade em geral.
A Empresa visa o fortalecimento de seu compromisso ético no exercício da sua Missão, de seus Valores e suas expectativas quanto às relações com seus clientes e parceiros, facilitando a tomada de decisão nos diversos níveis de seu processo de gestão.

        <br />
      </p>
    </div>
      <h2 class="ui header">
        Missão
      </h2>
    <p>
Valorizar os ativos de nossos clientes, apresentando resultados sustentáveis através de negociação, recebimento de créditos e resgate de garantias contratuais,
    </p>
      <h2 class="ui header">
        Visão
      </h2>
    <p>
Ser referência no mercado de comunicação e ser reconhecida como a melhor opção de clientes como uma empresa eficiente, focada em performance e inovação tecnológica    </p>

      <h2 class="ui header">
        Valores
      </h2>
    <p>
      <ul  class="ui list">
        <li class="item">Paixão pelo atendimento ao cliente </li>
        <li class="item">Respeito a dignidade e à diversidade do ser humano </li>
        <li class="item">Ética nos relacionamentos  </li>
        <li class="item">Comprometimento com os resultados  </li>
        <li class="item">Empatia </li>
      </ul>

   </p>



    </div>

     </div></>
  </Pagina>;
});
  