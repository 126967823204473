
import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
export default memo(function GestaoDePessoasPagina() {
    return <Pagina titulo={"Política de gestão de pessoas"}>
      <>
  <div class="ui container">
    <div class="ui container conteudo">

<p>
  O Grupo Aval tem como princípio respeitar os direitos humanos e promover a diversidade social, através da adoção de políticas de prevenção a todo e qualquer tipo de discriminação, assédio moral ou de qualquer outro tipo, preconceito, ou condição de trabalho indigna, tais como trabalho infantil, escravo ou análogo.
</p>

<p>
  O Código de Ética reflete o padrão ideal de conduta e valores compartilhados pelos nossos colaboradores, visando garantir a qualidade dos serviços prestados em conformidade com as leis aplicáveis, alcance dos objetivos empresariais, mediante cumprimento das regras básicas de comportamento, visando total satisfação dos nossos clientes, através de soluções rápidas e objetivas, bem como sempre aplicar os pilares descritos na Visão, Missão e Valores.
</p>
<h2 class="ui header">
  Cliente e Usuários
</h2>
<p>
  Sem confiança entre prestador e cliente não há relação próspera e positiva, com isso devemos prezar pela cortesia, atenção, eficácia e transparência em nossos atendimentos. Mantendo a confidencialidade de todas as informações recebidas, sempre prestando atendimento de forma clara e compreensível, identificando a necessidade do cliente para obter a melhor e mais rápida solução.
</p>
<h2 class="ui header">
Alta Administração
</h2>
<p>
   Devem-se divulgar todos os resultados e fatos relevantes de forma precisa, simétrica e sem privilégios.
</p>
<h2 class="ui header">
Concorrência
</h2>

<p>
  A concorrência é indispensável para a busca de melhores resultados e performance, por isso deve haver um tratamento adequado, respeitoso e sem abuso de poder. Respeitando a reputação dos concorrentes, e quando possível participar das iniciativas dos concorrentes, quando estas forem de interesse do mercado.
</p>
    </div>

     </div>

</>
    </Pagina>;
  });
  