
import React, { memo } from 'react';
import './sobre.sass'
import { Pagina } from '../../componentes/pagina';
export default memo(function SobrePagina() {
    return  <Pagina titulo={"Sobre a Aval"}>


        <div className="ui container conteudo sobre flex">
      <div className="imagem" style={{background: `url('./images/plaza.jpg')`}}></div>
      <div className="texto">
        <h2 className="ui header">
          <div className="content">Grupo Aval</div>
        </h2>
        <div className="sub header">Sobre a empresa</div>
        <div className="quemsomos">

Há mais de 42 anos dedica-se a prestação de serviços junto ao mercado financeiro, utilizando modernas técnicas de localização e contato com os clientes e a solução de pendências financeiras.
Somos voltados para agilização dos procedimentos a serem empreendidos, considerando sempre a qualidade na prestação de serviços e o atendimento personalizado aos nossos clientes.

        </div>
      </div>
    </div>
    </Pagina>;
  });
  