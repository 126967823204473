import React, { Children } from 'react'
import './sidebar.sass'
import menu from '../rotas/menu'
import {
    Checkbox,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react'
import { SidebarContext } from './sidebarContext'
import { Link } from 'react-router-dom'
import { DropDown } from './dropdown'
import CookieConsent from 'react-cookie-consent'

export function SidebarMenu({ children }) {
    const { isOpen, toggle } = React.useContext(SidebarContext)
    function handleToggle() {
        toggle();
      }
    return (


<>
<CookieConsent
        declineButtonText="Rejeitar"
        location="bottom"
        buttonText="Aceitar Todos os Cookies"
        cookieName="myAwesomeCookieName2"
        enableDeclineButton
        style={{ background: "#0e6b3f" }}
        buttonStyle={{
          color: "#fff", margin: '.5rem 4px', backgroundImage: 'linear-gradient(45deg, #fc7d00 0%, #fc7d00 59%, #fc9500 77%, #fc9500 100%)',
          fontSize: "1rem", padding: ".7rem", borderRadius: '.3rem'
        }}
        declineButtonStyle={{ color: "#fff", margin: '.5rem 4px', backgroundImage: 'linear-gradient(45deg, #7a7a7a 0%, #5e5e5e 100%)', fontSize: "1rem", padding: ".7rem", borderRadius: '.3rem' }}
        expires={150}
        debug="true"
      >
        Ao clicar em "Aceitar Todos os cookies", você concorda com o armazenamento de cookies no seudispositivo para melhorar a navegação no site,<br></br> analisar o uso do site e ajudar em nossos esforços em marketing. <Link to= "/politica/politica-de-privacidade" >Política de Privacidade e cookies</Link>
      </CookieConsent>
        <Sidebar.Pushable >
            <Sidebar
                as={Menu}
                animation='overlay'
                onHide={toggle}
                vertical
                visible={isOpen}
            >

                <>
                
                    <div class="logo">
                        <img src="logo.png"  />
                    </div>
                </>


                <div class="menu sub">


                    {
                        menu?.filter(f => f.nav).map((menu, i) => (
                            <>
                                {menu?.drop?.length > 1 ?
                                    <>
                                        <DropDown titulo={menu?.titulo}>
                                            {menu?.drop?.map(drop => (
                                                <Link className="item" to={drop.path} onClick={handleToggle}>{drop.titulo}</Link>
                                            ))}
                                        </DropDown>
                                    </> :
                                    <>
                                        <Link className="item" to={menu.path} onClick={handleToggle}>
                                            <h4 class="ui header">
                                                <div class="content">{menu.titulo}</div>
                                            </h4>

                                           </Link>
                                    </>}


                            </>

                        ))
                    }



                </div>

            </Sidebar>

            <Sidebar.Pusher dimmed={isOpen}>
                {children}
            </Sidebar.Pusher>
        </Sidebar.Pushable>
</>
    )
}

