import './parceiros.sass'
import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
export default memo(function ParceirosPagina() {
    return <Pagina titulo={"Clientes"}>
      <>
      <div class="ui container conteudo clientes">
      <div class="ui stackable three column grid">

        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Ativos S.A</div>
            </h2>
          </div>
        </div>
        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Bradesco</div>
            </h2>
          </div>
        </div>
        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Banco BV</div>
            </h2>
          </div>
        </div>
        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Itaú</div>
            </h2>
          </div>
        </div>
        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Banco Pan</div>
            </h2>
          </div>
        </div>

       

        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Pravaler</div>
            </h2>
          </div>
        </div>

        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Credpago</div>
            </h2>
          </div>
        </div>

          <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Mercado Pago</div>
            </h2>
          </div>
        </div>

        <div class="column">
          <div class="ui segment">
            <h2 class="ui header">
              <div class="content">Recovery</div>
            </h2>
          </div>
        </div>

      </div>
    </div>
    </>
    </Pagina>;
  });
  