import { Link, Outlet } from 'react-router-dom';
import { Rodape } from '../template/rodape/rodape';
import { Header } from '../template/topo/header';
import { HeaderPagina } from '../template/topo/headerPagina';
import './pagina.sass'
import { SidebarMenu } from './Sidebar';
export function Pagina({titulo, children}) {
    return (<>
    <SidebarMenu>
    <HeaderPagina/>
        <div>
            <div className="bar">
                <div className="ui container" >
                    <h4 className="ui header">
                        <div className="content">{titulo}</div>
                    </h4>
                </div>
            </div>
            
            <div className="ui conteudo pagina container">
                {children}
            </div>
        </div>
        <Outlet />
        <Rodape/>
        </SidebarMenu>

    </>)
}