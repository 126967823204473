

import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
export default memo(function CustomerExperiencePagina() {
    return <Pagina titulo={"Customer Experience"}>
<>
<div class="ui container conteudo ">
          <div class="ui grid">
            <div class=" wide sixteen column ">

              <p>
                Uma experiência notável do cliente é crítica para o crescimento sustentado de qualquer negócio. Uma experiência positiva do cliente promove a fidelidade, ajuda a reter clientes e incentiva a defesa da marca. Os clientes têm uma infinidade de opções à sua disposição, além dos recursos necessários para se educar e fazer compras por conta própria. É por isso que é tão importante proporcionar uma experiência marcante e fazer com que eles queiram continuar fazendo negócios com você - os clientes são o seu melhor recurso para aumentar o reconhecimento da sua marca. Então, como você pode medir a experiência do cliente para determinar o que está fazendo bem e onde há espaço para melhorias?
              </p>
              <h3 class="ui header">
                Como o Grupo Aval mede a experiência com o cliente?
              </h3>
              <p>
               Analisando os resultados da pesquisa de satisfação do cliente.

Identificando a taxa e os motivos da rotatividade de clientes.

Pedindo aos clientes solicitações de produtos ou recursos.

Analisando as tendências de opções de suporte ao cliente
              </p>
            </div>
          </div>
    <div class="ui stackable two column grid">

        <div class="column">
             <h3
        class="ui header"

      >
O que é a experiência do cliente?
      </h3>
                  <img src="./images/customerexperience-IMG-4.png" width="100%"/>

      <p>
A experiência do cliente, também conhecida como CX, é a percepção holística de seus clientes de sua experiência com seu negócio ou marca. O CX é o resultado de cada interação de um cliente com sua empresa, desde navegar no site até falar com o atendimento ao cliente e receber o produto / serviço que comprou de você. Tudo o que você faz afeta a percepção de seus clientes e a decisão de continuar voltando ou não - portanto, uma ótima experiência do cliente é a chave para o sucesso.

      </p>
      <p>
        Nos últimos anos, em todo o mundo acompanhamos o alto nível de corrupção em diversos setores da economia. Diversas empresas acabam tendo sua imagem e reputação fragilizadas devido ao seu comprovado envolvimento, tanto no âmbito empresarial quanto no político.
      </p>

            <h3
        class="ui header"

      >
Por que o CX é importante para o seu negócio?
      </h3>
      <p>
Oferecer uma ótima experiência ao cliente é extremamente importante para qualquer empresa. Quanto melhor a experiência dos clientes, mais avaliações personalizadas e positivas você receberá, ao mesmo tempo que reduz o atrito das reclamações e devoluções dos clientes. Os benefícios de entregar um ótimo CX incluem:
      </p>

      <p>

<ul class="ui list">
  <li>Aumento da lealdade do cliente  </li>
  <li>Aumento da satisfação do cliente melhor  </li>
  <li>Marketing boca a boca, análises positivas e recomendações</li>
</ul>
      </p>

        </div>
       <div class="column">
            <h3 class="ui header">
        O que é uma boa experiência do cliente?
      </h3>
                  <img src="./images/customerexperience-IMG-1.png" width="100%"/>

      <p>
        Não existe uma única lista de verificação universal a seguir para garantir uma boa experiência do cliente: seu negócio é único e seus clientes também. No entanto, encontramos uma série de princípios comuns pesquisando 2.000 profissionais CX em muitos setores. Você pode ler os resultados completos de nossa pesquisa aqui, mas incluímos alguns dos principais pontos a seguir. Resumindo, uma boa experiência do cliente pode ser alcançada se você: Faça de ouvir os clientes uma prioridade em toda a empresa Use o feedback do cliente para desenvolver uma compreensão profunda de seus clientes Implemente um sistema para ajudá-lo a coletar feedback, analisá-lo e agir regularmente Reduza o atrito e resolva os problemas específicos e desafios únicos de seus clientes
      </p>
      </div>
      </div>
      </div>
      </>
    </Pagina>;
  });
  