import './numeros.sass'
export function Numeros() {
    const informativos = [
        {
          titulo: "de experiência",
          valor: "42 anos",
          tipo: "",
          tipo: "amarelo",
          background: 'images/numeros/aval.png',
        },
        {
          titulo: "colaboradores",
          valor: "+ de 2600 mil",
          tipo: "amarelo",
          background: 'images/numeros/colaboradores.png',
        },
        {
          titulo: "interações Digitais por ano",
          valor: "+ 500 M",
          tipo: "verde",
          background: 'images/numeros/digital.jpg',
        },
        // {
        //   titulo: "em dívidas de cobrança",
        //   valor: "+R$38 BI ",
        //   tipo: "amarelo",
        //   background: require('../../assets/images/noticias/noticia1.jpeg'),
        // },
        {
          titulo: "de clientes na base",
          valor: "1,5 MM ",
          tipo: "verde",
          background: 'images/numeros/devedores.jpg',
        },
        {
          titulo: "interações por ano",
          valor: "+R$38 MM ",
          tipo: "verde",
          background: 'images/numeros/interacoes.png',
        },
      ];
  
    return(<>
        <div class="informativo">
        <h2 class="ui center aligned header">O GRUPO AVAL EM NÚMEROS</h2>
        <div class="ui container">
          <div class="ui stackable three column grid">

            {
                informativos.map(item => (

                    <div class="column" >
                    <div className={`card ${item.tipo}`} >
          
                      <img src={item.background} alt=""/>
                      <div class="box">
                      <div class="label">
                        { item.valor }
                      </div>
                      <p>
                        { item.titulo }
                      </p>
                    </div>
                    </div>
                  </div>
                ))
            }
          
          </div>
        </div>
      </div>
        </>
        )
}