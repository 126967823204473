import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
import {SidebarMenu} from '../../componentes/Sidebar';
import { Rodape } from '../../template/rodape/rodape';
import { Header } from '../../template/topo/header';
import { Numeros } from './numeros';
import { Pandape } from './pandape';
import { QuemSomos } from './quemsomos';
import { SegundaVia } from './segundavia';
export default memo(function PaginaPrincipal() {
    return <>
<SidebarMenu>
<Header/>
<div style={{zIndex: '980',position: 'relative'}}>
<SegundaVia/>
<Pandape/>
<Numeros/>
<QuemSomos/>
</div>


<Rodape/>
</SidebarMenu>
    </>;
  });
  