
import React, { useRef, useState } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Pagina } from '../../componentes/pagina';
export default function Sustentabilidade() {
    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);

      const { pdfDocument} = usePdf({
        file: "./pdf/RELATORIO DE SUSTENTABILIDADE 2023.pdf",
        page,
        canvasRef,
      });
  return (
    <>
    <Pagina titulo={"Relatório de Sustentabilidade"}>

      {!pdfDocument && <span>Loading...</span>}
      <div className="pdf">

<canvas ref={canvasRef} />
{Boolean(pdfDocument && pdfDocument.numPages) && (
  <nav>

        <button               class="ui button"
disabled={page === 1} onClick={() => setPage(page - 1)}>
          Anterior
        </button>

        <button
        class="ui button"
          disabled={page === pdfDocument.numPages}
          onClick={() => setPage(page + 1)}
        >
          Proximo
        </button>
 
  </nav>
)}
</div>


     </Pagina>
    </>
  );
}


