
import React, { memo } from 'react';
import { Pagina } from '../../componentes/pagina';
import './compliance.sass'
export default memo(function CompliancePagina() {
    return <Pagina titulo={"Compliance"}>

      <>
      <div class="ui container conteudo compliance">
<div class="ui  grid">
<div class="row">
<div class="column">




<h3 class="ui header"> 
O conceito de compliance nos negócios visa gerar valor para uma organização e garantir sua sobrevivência.
</h3>
<div class="efeito">  <img 
      src="./images/compliancenovo.jpg"
      width="100%"

    /></div>


<h4 class="ui header">  Essa prática decorre dos grandes impactos financeiros causados por fatores como: 
</h4>
<ol class="ui list">

<li>Ausência de diretrizes normativas; </li>
<li>Desalinhamentos com as leis aplicáveis; </li>
<li>Falta de ferramentas preventivas adequadas;</li>
<li>Falhas de gerenciamento de processos; </li>
<li>Operações sem sistema de informação estruturado.</li>

</ol>


<p>
As atividades de nossa empresa estão em total conformidade com as regras e leis aplicadas no segmento. Todos os nossos colaboradores e fornecedores estão de acordo com as normas dos órgãos reguladores. Além disso, zelamos pelo fiel cumprimento dos diversos instrumentos normativos de nossos parceiros.
</p>

<p>
Nós trabalhamos com transparência, e um grau crescente de maturidade de gestão. No qual, os gestores e equipes estão no controle dos processos e procedimentos, implementando-os e executando-os com eficiência. 
</p>
<p>
Para proteger a imagem de nossos clientes focamos em nossas práticas de governança corporativa e segurança da informação, todas nossas ações e resultados obtidos constituem uma organização segura e sólida. 
</p>
</div>
</div>
</div>
</div>

</>
    </Pagina>;
  });
  