
const menu = [
    {
      path: '/',
    },
    {
      nav: true,
      path: '/ogrupo',
      titulo: 'O grupo',
      drop: [
        {
          path: '/ogrupo',
          titulo: 'Sobre a aval',
        },
        {
          path: '/ogrupo/filiais',
          titulo: 'Unidades',
        },
        {
          path: '/ogrupo/parceiros',
          titulo: 'Parceiros',
        },
        {
          path: '/ogrupo/gestaodepessoas',
          titulo: 'Gestão de pessoas',
        },
        {
          path: '/ogrupo/compliance',
          titulo: 'Compliance',
        },
        {
          path: '/ogrupo/customerexperience',
          titulo: 'Customer Experience',
        },
        {
          path: '/ogrupo/codigo-de-etica',
          titulo: 'Código de Ética',
        },
        {
          path: '/ogrupo/programa-de-integridade',
          titulo: 'Programa de integridade',
        },
        {
          path: '/ogrupo/relatorio-de-sustentabilidade',
          titulo: 'Relatório de Sustentabilidade',
        },
        {
          path: '/ogrupo/politicas-direitos-humanos',
          titulo: 'Políticas de Direitos Humanos',
        },
  
      ]
    }, 
    {
      nav: true,
      path: '/',
      titulo: 'Carreira',
      drop: [
        {
          path: '/carreira/missaovisaoevalores',
          titulo: 'Missão visão e valores',
  
        },
        {
          path: '/carreira',
          titulo: 'Carreira',
  
        }
      ]
  
    },
    {
      nav: false,
      path: '/blog',
      titulo: 'Blog',
  
    }, {
      nav: true,
      path: '/treinamento',
      titulo: 'Treinamento',
    },
    {
      nav: true,
      titulo: 'Fale conosco',
      path: '/contato',
      drop: [
        {
          titulo: 'Canais de Contato ',
          path: '/contato/canais',
  
        },
        {
          titulo: 'Fale conosco',
          path: '/contato',
  
        }
      ]
  
    }
  
  ]
  export default menu;