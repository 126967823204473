import React from 'react';
import { Pagina } from '../../../componentes/pagina';

export default function TermosdeUsoPagina() {

return(<>

<Pagina titulo={"TERMOS E CONDIÇÕES GERAIS DE CONTRATAÇÃO E USO DO SITE"}>

<>



Por favor, leia com atenção os termos e condições (“TERMOS DE USO”). Eles dizem respeito à relação entre o <strong>SITE</strong> <strong>“www.grupoaval.com.br”</strong>, de titularidade da <strong>EMPRESA</strong> <strong>Grupo Aval</strong>, qualificada no formulário de contratação, e você, <strong>USUÁRIO</strong> dos serviços (“<strong>USUÁRIO</strong>”). Ao se cadastrar no <strong>SITE</strong> ou aplicativo você declara <strong>ESTAR DE ACORDO COM OS TERMOS DE USO</strong>. Note que a recusa dos TERMOS DE USO do impedirá que você utilize as funcionalidades do <strong>SITE</strong>.
<br></br>
Os TERMOS DE USO ficam incorporados para todos os fins e efeitos aos contratos celebrados digitalmente para ingresso no <strong>SITE</strong> bem como em todos os demais documentos, acordos e contratos firmados ou concluídos entre <strong>EMPRESA</strong> e <strong>USUÁRIO</strong>.

<h3>
1. OBJETO
</h3>
<br></br>

<small>1.1</small> O objeto dos TERMOS DE USO e dos contratos eventualmente celebrados entre <strong>EMPRESA</strong> e <strong>USUÁRIO</strong> será a prestação dos serviços e o fornecimento dos produtos a seguir descritos: (…)
<br></br>

<h3>2. CONDIÇÕES DE USO E OBRIGAÇÕES DO <strong>USUÁRIO</strong>
</h3><br></br>

<small>2.1</small> É obrigação do <strong>USUÁRIO</strong> fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente (em todas as searas jurídicas) por todo o conteúdo por si informado no item cadastro, mantendo atualizado e confirmado o endereço para entrega dos produtos e serviços encomendados.
<br></br>

<small>2.2 </small>O <strong>USUÁRIO</strong> se obriga, também, a pagar integralmente o preço dos produtos e serviços por si solicitados ou encomendados no <strong>SITE</strong>.
<br></br>

<small>2.3</small> O <strong>USUÁRIO</strong> se compromete a utilizar todos os softwares disponibilizados no <strong>SITE</strong> para os fins descritos nestes TERMOS DE USO.
<br></br>

<small>2.4</small> O <strong>USUÁRIO</strong> será automaticamente descadastrado, caso identificada conduta de sua autoria em desacordo com os TERMOS DE USO.
<br></br>
<h3>
3. MODIFICAÇÕES DOS TERMOS DE USO

</h3>
<br></br>

<small>3.1</small> Os TERMOS DE USO poderão, a qualquer tempo, ter seu conteúdo, ou parte dele, modificados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços e produtos disponibilizados.
<br></br>

<small>3.2</small> As novas condições entrarão em vigência assim que veiculadas no <strong>SITE</strong> e informadas ao <strong>USUÁRIO</strong>, sendo possível a este manifestar oposição às modificações, desde que o faça no <strong>SITE</strong>, o que irá ocasionar o cancelamento de seu cadastro.
<br></br>

<h3>4. PROPRIEDADE INTELECTUAL</h3>
<br></br>

<small>4.1</small> Todos e quaisquer direitos de propriedade intelectual ou industrial relativos ao <strong>SITE</strong> pertencem única e exclusivamente à <strong>EMPRESA</strong>. Em nenhuma hipótese, os TERMOS DE USO ou os contratos implicarão transferência, no todo ou em parte, de qualquer direito de propriedade intelectual ou industrial ao <strong>USUÁRIO</strong>.
<br></br>

<small>4.2</small> O <strong>USUÁRIO</strong> se compromete a utilizar o <strong>SITE</strong> de acordo com as suas finalidades e exigências técnicas; dispor de meios adequados para a implantação e a utilização dos softwares, incluindo, sem limitação, hardware, rede e pessoal adequados etc.; responsabilizar-se legalmente
<br></br>

por quaisquer dados e informações que venham a ser armazenados; não fazer ou distribuir quaisquer cópias dos softwares; não alterar, combinar, adaptar, traduzir, decodificar, fazer ou solicitar a terceiros engenharia reversa dos softwares; não criar trabalhos deles derivados ou solicitar que terceiros o façam; e não ceder, licenciar, sublicenciar ou de qualquer outra forma dispor dos softwares.

</>

    </Pagina></>) 

}