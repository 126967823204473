import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Navigation ,Pagination } from "swiper";
import { Pagina } from '../../componentes/pagina';
import './carreira.sass'
export default memo(function CarreiraPagina() {
  return <Pagina titulo={"Carreira"}>

    <div className="ui container carreira">
      <div className="ui container conteudo">
        <p>
          O Grupo Aval pauta suas ações no aprimoramento constante de seus
          serviços, evidenciando valores como respeito a seus clientes, melhoria
          contínua dos processos e o crescimento qualitativo dos colaboradores
          através de investimento sólido em novas tecnologias e treinamentos
          constantes.
          <br />
          Grupo Aval,
          especializada em comunicação nos âmbitos extrajudicial e
          judicial, apresenta em seu Código de Ética empresarial os
          principais direcionadores das políticas adotadas pela empresa, que
          norteiam as ações e relações com sócios, clientes,
          parceiros,fornecedores, colaboradores e comunidade em geral.
          <br />
          A Empresa visa o fortalecimento de seu compromisso ético no exercício da
          sua Missão, de seus Valores e suas expectativas quanto às relações com
          seus clientes e parceiros, facilitando a tomada de decisão nos diversos
          níveis de seu processo de gestão.
          <br />
        </p>
      </div>

      <div  className="ui stackable one column grid">

      <div className="flex-texto">

        <div className="ui fluid  inclusao">
          <h2 className="ui header">
            Programa de inclusão social
            <p className="sub header">
              A fim de proporcionar o desenvolvimento profissional dos
              colaboradores, o Grupo Aval oferece oportunidades de carreira
              através de divulgação interna de vagas em diversos setores.
                Valoriza a diversidade, tendo em seu quadro de colaboradores
                pessoas de diferentes regiões, etnias e classes sociais,
                respeitando e valorizando de maneira uniforme todos os seus
                colaboradores. Incentivo ao primeiro emprego, oferecendo trabalho
                a jovens aprendizes com idade entre 16 e 24 anos. O Grupo Aval
                  não admite a utilização de mão de obra infantil, forçada ou
                  escrava ou análoga em suas relações trabalhistas e em sua cadeia
                  produtiva.
                </p>
              </h2>
              <img src="./images/inclusao_social.jpg" className="imagem" width="100%" />

              <h3 className="ui header">
                Transparência

                <p className="sub header">
                  O Grupo Aval prima pela transparência e está comprometida com a veracidade das informações prestadas a todas as partes interessadas.

                  A organização tem a obrigação como organismo social de ser transparente e séria quanto as informações referentes a toda e qualquer atividade que impacte ou incida na vida das pessoas e no meio ambiente.

                  Convém que uma organização divulgue de forma clara, precisa e completa, e em grau razoável e suficiente, as políticas, decisões e atividades pelas quais é responsável, inclusive seus impactos conhecidos e prováveis na sociedade e no meio ambiente. Convém que essas informações estejam prontamente disponíveis e sejam diretamente acessíveis, assim como sejam compreensíveis para aqueles que tenham sido ou possam vir a ser afetados de modo significativo pela organização.
                </p>
              </h3>
              <h3 className="ui header">
                Sigilo

                <p className="sub header">
                  No exercício de sua Missão, o Grupo Aval exige de todos os Colaboradores a manutenção do sigilo das informações de caráter privado, obtidas e mantidas no exercício de suas atividades.</p>

              </h3>
              <h3 className="ui header">
                Diversidade

                <p className="sub header">
                  É contrária a toda forma de discriminação e valoriza as oportunidades oferecidas pela riqueza étnica e cultural da sociedade.</p>

              </h3>
              <h3 className="ui header">
                Práticas Inaceitáveis em nossa empresa:

                <p className="sub header">
                  Qualquer tipo de discriminação seja ela política, econômica, social, racial, de nacionalidade, gênero, idade, religião ou de orientação sexual.
                  Constranger alguém com intuito de levar vantagem ou favorecimento sexual/moral, prevalecendo-se o agente da sua condição de superior hierárquico, ou ascendência inerentes ao exercício de emprego, cargo ou função.</p>

              </h3>
            </div>
        </div>

        <div className="flex-texto">
          <div className="ui divider"> </div>

          <div className="ui fluid ">
            <h2 className="ui header">
              Meu primeiro emprego
              <p className="sub header">
                O que há em um trabalho? Para quem consegue um, é uma chance de se construir no espaço da carreira. Para quem não tem, é uma chance de finalmente começar uma carreira.
                No entanto, uma pergunta recente que despertou nosso interesse era de natureza específica - Quão importante é seu primeiro emprego?


              </p>

            </h2>
            <img src="./images/primeiro_emprego.jpg" className="imagem " width="100%" />

            <h3 className="ui header">
              Aprendendo

              <p className="sub header">
                Por aprendizagem, não queremos dizer apenas aprender sobre suas funções e responsabilidades de trabalho, mas algo muito mais essencial. Em seu primeiro emprego, você aprende muito sobre como deseja que seja seu futuro. É como o momento de epifania da sua carreira.
                Já que você ainda tem a opção de se desviar de sua escolha de carreira, é importante aprender bem sobre o trabalho e explorar outros nos quais você possa estar interessado. Você não pode continuar mudando de carreira com qualquer outro trabalho, então você precisa explorar ( aprender sobre) tantas opções em seu primeiro emprego.
              </p>
            </h3>
            <h3 className="ui header">
              Contatos


              <p className="sub header">
                As pessoas com quem você encontra durante seu primeiro emprego são muito mais importantes do que seu salário. Como você é muito novo no setor, precisa se comunicar com pessoas importantes e manter conexões com elas.
                Isso não apenas ajuda no processo de aprendizagem, mas também ajuda a obter melhores opções no futuro por meio desses contatos. Não se trata de usar suas fontes para conseguir um emprego, mas de conhecer melhor o canal por meio das pessoas certas.
              </p>

            </h3>

            <h3 className="ui header">
              Experiência

              <p className="sub header">
                Sim, qualquer trabalho pode lhe dar experiência, mas é algo diferente com o seu primeiro emprego. Não será sempre que você cometerá um erro e ainda estará motivado, pois você é novo no trabalho. Portanto, não tenha medo de cometer erros em seu primeiro emprego e aprender com eles.
                É o único momento em que você não é julgado com severidade, em vez de identificar seus erros e sugerir medidas corretivas. Se você não iniciar o processo de aprendizagem com seu primeiro emprego, seria muito difícil fazê-lo mais adiante em sua carreira. Então, por essa ótica, seu primeiro trabalho significa muito. Além disso você crescerá dentro do seu emprego, terá novas oportunidades é preciso apenas agarra-las.
              </p>
            </h3>
          </div>


        </div>
      </div>
      <a
        class="ui button trabalho" href="https://grupoaval.pandape.com.br/" target="_blank">Trabalhe conosco !</a>

      <div class="ui grid lista">
        <div class="row">
          <div class="column">
            <h2 class="ui aligned header">Benefícios Grupo Aval</h2>
          </div>
        </div>
        <div class="ui row">
          <div class="listagem">
            <div class="box">
              <div class="item">
                <i class="check icon"></i>
                Vale-alimentação ou Refeição;
              </div>
              <div class="item">
                <i class="check icon"></i>
                Vale-transporte;
              </div>

              <div class="item">
                <i class="check icon"></i>
                Plano de saúde;
              </div>
              <div class="item">
                <i class="check icon"></i>
                Auxílio-creche;
              </div>
            </div>
            <div class="box">
              <div class="item">
                <i class="check icon"></i>
                Empréstimo Consignado;
              </div>
              <div class="item">
                <i class="check icon"></i>
                Convênio com o SESC;
              </div>
              <div class="item">
                <i class="check icon"></i>
                Descontos em parceiros como: restaurantes, academia, escolas e
                lojas.
              </div>
            </div>
            <p class="alerta">
              (Benefícios específicos variam de acordo com o local)
            </p>
          </div>
        </div>
        <div class="row">
          <div class="column">
            <h4 class="ui aligned header">
              CONVÊNIOS
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="column carousel">
   

          <Swiper
    navigation={true}
    loop={true}
    slidesPerView={5}
    slidesPerColumn={2} spaceBetween={30}
            pagination={{
              dynamicBullets: true,
            }}
            style={{
              "--swiper-navigation-color": "#fc7d00 ",
              "--swiper-pagination-color": "#fc7d00 ",
           
            }}
            lazy={true}
            modules={[Autoplay, Pagination, Navigation]}
 
      onSwiper={(swiper) => console.log(swiper)}
    >
        <SwiperSlide>
            <img src="./images/planos/amorumbi.png"   data-content="20% de desconto nas mensalidades de Graduação, EaD, Pós Graduação e MBA." data-variation="basic" /> 
    </SwiperSlide>   

    
    <SwiperSlide>
            <img src="./images/planos/fiap.png"   data-content="10% de desconto nas mensalidades de Graduação e Fiap School. 20% de desconto nas mensalidades de cursos MBA e Cursos de Curta Duração." data-variation="basic"/> 
    </SwiperSlide>   

    
    <SwiperSlide>
            <img src="./images/planos/amorumbi.png"  data-content="20% de desconto nas mensalidades de Graduação, EaD, Pós Graduação e MBA." data-variation="basic"/>
    
    </SwiperSlide>   

 

    
    <SwiperSlide>

   <img src="./images/planos/fmu.png" data-content="10% de desconto nas mensalidades de Graduação e EaD. 20% de desconto nas mensalidades de Pós Graduação e Cursos de Extensão." data-variation="basic" /> 
    
    </SwiperSlide>   


    <SwiperSlide>
      

              <img src="./images/planos/saojudas.png"   data-content="50% de desconto nas mensalidades de Tecnológo, Bacherelado e Licenciatura. 30% de desconto nas mensalidades de Pós Graduação, Pós Graduação pela Una/Ebradi e Cursos Preparatórios pela Una/Ebradi. 10% de desconto nas mensalidades de Cursos de Curta Duração." data-variation="basic"/> 
    
    </SwiperSlide>   
    
    <SwiperSlide>
  
              <img src="./images/planos/unip.png"   data-content="35% de desconto nas mensalidades de Graduação Presencial e a Distância  (Bacharel, Licenciatura e Tecnológo)" data-variation="basic"/>
    </SwiperSlide>      
    <SwiperSlide>
              <img src="./images/planos/uninove.png"  data-content="10% de desconto nas mensalidades de Graduação Presencial e a Distância para quem é aluno. Para ingressantes 20% de desconto nas mensalidades de Graduação no 1º e 2º Semestre."  /> 
    </SwiperSlide>      
    <SwiperSlide>

<img src="./images/planos/yazigi.png"   data-content="Idiomas: Inglês e Espanhol.20% de desconto nas mensalidades.50% de desconto na Matrícula.Benefícios para filhos e cônjuge." data-variation="basic" />   
           
    </SwiperSlide>      
    <SwiperSlide>
  

<img src="./images/planos/justfit.png"  data-content="25% de desconto no Plano Fit Plus.Isenção da Taxa de Matrícula.Isenção da AnuidadeMensalidade por 79,90.Sem multa de cancelamento" data-variation="basic"/>
    
    </SwiperSlide>      
    <SwiperSlide>
  
 <img src="./images/planos/cruzeirodosul.png"  data-content="10% de desconto nas mensalidades de Graduação;
10% de desconto nas mensalidades de Graduação EaD;
20% de descontos nas mensalidades de Pós Graduação;
30% de desconto nas mensalidades de Pós Graduação EaD;
20% de desconto nas mensalidades de Cursos de Extensão;
10% de desconto nas mensalidades do Colégio Cruzeiro do Sul;
" data-variation="basic" /> 
    </SwiperSlide>      
 
    </Swiper>



      


       
          

          </div>

        </div>
      </div>

  </div>
  
  
  </Pagina >;
});
