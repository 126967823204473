import { useState } from "react"
export function DropDown({titulo, children,icon}) {
    const [drop,setDropDown] = useState(false)
    return (
    <>
    <div className={!drop ? 'hidden' : ''} >
      <div className="titulo item" onClick={() => setDropDown(!drop)}>

              
      <h4 className="ui header">
                <div className="content">
       {titulo}
                  <i className="dropdown icon"></i>
                </div>
              </h4>
              
      </div>
      <div class="menu sub">
      {children}
      </div>
    </div>
  
    </>)
}